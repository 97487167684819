<template>

    <!-- Header -->
    <header v-if="!isInitializing">
      <div class="h-header border-b border-gray-300 ">
          <ul class="flex w-full h-header justify-between">
              <li 
                v-for="view, i in VIEWS"
                @click="activeView = view"
                :key="view" 
                :class="[
                    (activeView == view) ? 'border-b-4 border-gray-600' : '',
                    'w-full flex items-center justify-center',
                ]">
                <a href="#"   
                  :class="[
                    i != 0 ? 'border-l border-dashed border-gray-300' : '',
                    'cursor-pointer px-4 py-1 text-xs sm:text-sm font-base leading-4 text-gray-700 h-full w-full flex items-center justify-center'
                  ]"
                >
                    <span>
                        {{ view }}
                    </span>
                    <div v-if="(view == VIEWS[0] && (purchasedUsers.length != 0 || purchasedUsersCaapLite.length != 0 || purchasedUsersCaapPlus.length != 0 || purchasedUsersMtp.length != 0))">
                      <span class="ml-1 bg-gray-200 text-gray-700 py-0.5 px-2 rounded-full text-xs font-mediuminline-block">
                        {{ purchasedUsers.length + purchasedUsersCaapLite.length + purchasedUsersCaapPlus.length + purchasedUsersMtp.length }}
                      </span>
                    </div>
                    <div v-else-if="(view == VIEWS[1] && todaysUsers.length != 0)">
                      <span class="ml-1 bg-gray-200 text-gray-700 py-0.5 px-2 rounded-full text-xs font-mediuminline-block">
                        {{ todaysUsers.length }}
                      </span>
                    </div>
                    <div v-else-if="(view == VIEWS[2] && newLicensedUsers.length != 0)">
                      <span class="ml-1 bg-gray-200 text-gray-700 py-0.5 px-2 rounded-full text-xs font-mediuminline-block">
                        {{ newLicensedUsers.length }}
                      </span>
                    </div>
                    
                </a>
              </li>
          </ul>
      </div>
    </header>
    
  
    <!-- Main Content -->
    <main 
      v-if="!isInitializing" 
      :class="[
        (isWaiting) ? 'pointer-events-none cursor-wait opacity-25' : '',
        'overflow-auto h-full p-4 scrollbar-hide'
      ]">

      <!-- Reviewers badge tab -->
      <div v-if="activeView != VIEWS[2]" class="flex justify-start items-center gap-2 mb-5">
        
        <button @click="revToShow = 'caap'" class="py-1 px-4 border rounded-full cursor-pointer flex justify-center items-center" :class="revToShow == 'caap' ? 'bg-gray-300':'bg-gray-50'">
          <span>CAAP</span>
          <div v-if="purchasedUsers.length != 0 && activeView == VIEWS[0]">
            <span class="ml-1 bg-gray-200 text-gray-700 py-0.5 px-2 rounded-full text-xs font-mediuminline-block">
              {{ purchasedUsers.length }}
            </span>
          </div>
        </button>

        <button @click="revToShow = 'caap_lite'" class="py-1 px-4 border rounded-full cursor-pointer flex justify-center items-center" :class="revToShow == 'caap_lite' ? 'bg-gray-300':'bg-gray-50'">
          <span>CAAP Lite</span>
          <div v-if="purchasedUsersCaapLite.length != 0 && activeView == VIEWS[0]">
            <span class="ml-1 bg-gray-200 text-gray-700 py-0.5 px-2 rounded-full text-xs font-mediuminline-block">
              {{ purchasedUsersCaapLite.length }}
            </span>
          </div>
        </button>

        <button @click="revToShow = 'caap_plus'" class="py-1 px-4 border rounded-full cursor-pointer flex justify-center items-center" :class="revToShow == 'caap_plus' ? 'bg-gray-300':'bg-gray-50'">
          <span>CAAP Plus</span>
          <div v-if="purchasedUsersCaapPlus.length != 0  && activeView == VIEWS[0]">
            <span class="ml-1 bg-gray-200 text-gray-700 py-0.5 px-2 rounded-full text-xs font-mediuminline-block">
              {{ purchasedUsersCaapPlus.length }}
            </span>
          </div>
        </button>

        <button @click="revToShow = 'mtp'" class="py-1 px-4 border rounded-full cursor-pointer flex justify-center items-center" :class="revToShow == 'mtp' ? 'bg-gray-300':'bg-gray-50'">
          <span>MTP</span>
          <div v-if="purchasedUsersMtp.length != 0  && activeView == VIEWS[0]">
            <span class="ml-1 bg-gray-200 text-gray-700 py-0.5 px-2 rounded-full text-xs font-mediuminline-block">
              {{ purchasedUsersMtp.length }}
            </span>
          </div>
        </button>

      </div>

      <!-- NEW BUYERS VIEW -->
      <ul v-if="activeView == VIEWS[0]" class="flex flex-col space-y-4">


        <!-- CAAP -->
        <div v-if="revToShow == 'caap'">
          <div v-if="purchasedUsers.length == 0">
            Nothing to display
          </div>
          <ul v-else class="flex flex-col space-y-4">
            <li v-for="user in purchasedUsers" :key="user.id" class="border border-gray-300 text-xs sm:text-sm flex flex-col ">
              <div class="px-5 py-4">
                
                <p><strong class="font-semibold">Full Name:</strong> {{ user.firstname }} {{ user.lastname }}</p>
                <p><strong class="font-semibold">Email:</strong> {{ user.email }}</p>
                <p><strong class="font-semibold">Date Signed Up:</strong> {{ moment.unix(user.date_created/1000).format('LLLL') }}</p>
                <p><strong class="font-semibold">Endorsed By:</strong> {{ user.endorser }}</p>
                <p v-if="user.reviewers.caap != 'inactive'"><strong class="font-semibold">Purchase Date:</strong> {{ (user.caap_data) ? moment.unix(user.caap_data.purchase_date/1000).format('LLLL') : ''}}</p>
                <p v-if="user.reviewers.caap != 'inactive'"><strong class="font-semibold">GCash Ref. No.:</strong> {{ (user.caap_data) ? user.caap_data.gcash_refno : '' }}</p>
                <p v-if="user.reviewers.caap != 'inactive'"><strong>Purchase Mode:</strong> {{ (user.caap_data && user.caap_data.activateLater) ? 'User wants to activate later.' : 'User wants to activate immediately.' }}</p>
                <p v-if="user.reviewers.caap != 'inactive'"><strong>Coupon Code:</strong> {{ (user.caap_data && user.caap_data.couponCode) ? user.caap_data.couponCode : 'None' }}</p>
                
                <div class="bg-gray-100 py-3 mt-4 text-xs text-black px-3">
                  <code>
                    {{ user.caap_data }}
                  </code>
                </div>
              </div>
              <div class="flex justify-between space-x-2 px-4 bg-gray-50 py-3">
                <button @click="revokePurchase(user,'caap')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                  <HandThumbDownIcon class="w-4 h-4"/>
                  <span>Revoke</span>
                </button>
                <button @click="confirmUserPurchase(user,'caap')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                    <HandThumbUpIcon class="w-4 h-4"/>
                    <span>Confirm</span>
                </button>
              </div>
            </li>
          </ul>
        </div>

         <!-- CAAP Lite -->
         <div v-if="revToShow == 'caap_lite'">
          <div v-if="purchasedUsersCaapLite.length == 0">
            Nothing to display
          </div>
          <ul v-else class="flex flex-col space-y-4">
            <li v-for="user in purchasedUsersCaapLite" :key="user.id" class="border border-gray-300 text-xs sm:text-sm flex flex-col ">
              <div class="px-5 py-4">
                <p><strong class="font-semibold">Full Name:</strong> {{ user.firstname }} {{ user.lastname }}</p>
                <p><strong class="font-semibold">Email:</strong> {{ user.email }}</p>
                <p><strong class="font-semibold">Date Signed Up:</strong> {{ moment.unix(user.date_created/1000).format('LLLL') }}</p>
                <p><strong class="font-semibold">Endorsed By:</strong> {{ user.endorser }}</p>
                <p v-if="user.reviewers.caap_lite != 'inactive' && user.reviewers.caap_lite != ''"><strong class="font-semibold">Purchase Date:</strong> {{ (user.caap_lite_data) ? moment.unix(user.caap_lite_data.purchase_date/1000).format('LLLL') : ''}}</p>
                <p v-if="user.reviewers.caap_lite != 'inactive' && user.reviewers.caap_lite != ''"><strong class="font-semibold">GCash Ref. No.:</strong> {{ (user.caap_lite_data) ? user.caap_lite_data.gcash_refno : '' }}</p>
              </div>
              <div class="flex justify-between space-x-2 px-4 bg-gray-50 py-3">
                <button @click="revokePurchase(user,'caap_lite')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                    <HandThumbDownIcon class="w-4 h-4"/>
                    <span>Revoke</span>
                </button>
                <button @click="confirmUserPurchase(user,'caap_lite')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                    <HandThumbUpIcon class="w-4 h-4"/>
                    <span>Confirm</span>
                </button>
              </div>
            </li>
          </ul>
        </div>

        <!-- CAAP Plus -->
        <div v-if="revToShow == 'caap_plus'">
          <div v-if="purchasedUsersCaapPlus.length == 0">
            Nothing to display
          </div>
          <ul v-else class="flex flex-col space-y-4">
            <li v-for="user in purchasedUsersCaapPlus" :key="user.id" class="border border-gray-300 text-xs sm:text-sm flex flex-col ">
              <div class="px-5 py-4">
                <p><strong class="font-semibold">Full Name:</strong> {{ user.firstname }} {{ user.lastname }}</p>
                <p><strong class="font-semibold">Email:</strong> {{ user.email }}</p>
                <p><strong class="font-semibold">Date Signed Up:</strong> {{ moment.unix(user.date_created/1000).format('LLLL') }}</p>
                <p><strong class="font-semibold">Endorsed By:</strong> {{ user.endorser }}</p>
                <p v-if="user.reviewers.caap_plus != 'inactive' && user.reviewers.caap_plus != ''"><strong class="font-semibold">Purchase Date:</strong> {{ (user.caap_plus_data) ? moment.unix(user.caap_plus_data.purchase_date/1000).format('LLLL') : ''}}</p>
                <p v-if="user.reviewers.caap_plus != 'inactive' && user.reviewers.caap_plus != ''"><strong class="font-semibold">GCash Ref. No.:</strong> {{ (user.caap_plus_data) ? user.caap_plus_data.gcash_refno : '' }}</p>
              </div>
              <div class="flex justify-between space-x-2 px-4 bg-gray-50 py-3">
                <button @click="revokePurchase(user,'caap_plus')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                    <HandThumbDownIcon class="w-4 h-4"/>
                    <span>Revoke</span>
                </button>
                <button @click="confirmUserPurchase(user,'caap_plus')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                    <HandThumbUpIcon class="w-4 h-4"/>
                    <span>Confirm</span>
                </button>
              </div>
            </li>
          </ul>
        </div>

        <!-- MTP -->
        <div v-if="revToShow == 'mtp'">
          <div v-if="purchasedUsersMtp.length == 0">
            Nothing to display
          </div>
          <ul v-else class="flex flex-col space-y-4">
            <li v-for="user in purchasedUsersMtp" :key="user.id" class="border border-gray-300 text-xs sm:text-sm flex flex-col ">
              <div class="px-5 py-4">
                <p><strong class="font-semibold">Full Name:</strong> {{ user.firstname }} {{ user.lastname }}</p>
                <p><strong class="font-semibold">Email:</strong> {{ user.email }}</p>
                <p><strong class="font-semibold">Date Signed Up:</strong> {{ moment.unix(user.date_created/1000).format('LLLL') }}</p>
                <p><strong class="font-semibold">Endorsed By:</strong> {{ user.endorser }}</p>
                <p v-if="user.reviewers.mtp != 'inactive' && user.reviewers.mtp != ''"><strong class="font-semibold">Purchase Date:</strong> {{ (user.mtp_data) ? moment.unix(user.mtp_data.purchase_date/1000).format('LLLL') : ''}}</p>
                <p v-if="user.reviewers.mtp != 'inactive' && user.reviewers.mtp != ''"><strong class="font-semibold">GCash Ref. No.:</strong> {{ (user.mtp_data) ? user.mtp_data.gcash_refno : '' }}</p>
              </div>
              <div class="flex justify-between space-x-2 px-4 bg-gray-50 py-3">
                <button @click="revokePurchase(user,'mtp')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                    <HandThumbDownIcon class="w-4 h-4"/>
                    <span>Revoke</span>
                </button>
                <button @click="confirmUserPurchase(user,'mtp')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                    <HandThumbUpIcon class="w-4 h-4"/>
                    <span>Confirm</span>
                </button>
              </div>
            </li>
          </ul>
        </div>

      </ul>

      <!-- TODAY'S SIGNUPS VIEW -->
      <div v-else-if="activeView == VIEWS[1]" class="flex flex-col space-y-4">
        
        <div v-if="todaysUsers.length == 0">
          Nothing to display
        </div>

        <div v-else>
          <!-- CAAP -->
          <ul v-if="revToShow == 'caap'">
            <li v-for="user in todaysUsers" :key="user.id" class="border border-gray-300 text-xs sm:text-sm flex flex-col ">
              <div class="px-5 py-4">
                <p><strong class="font-semibold">Full Name:</strong> {{ user.firstname }} {{ user.lastname }}</p>
                <p><strong class="font-semibold">Email:</strong> {{ user.email }}</p>
                <p><strong class="font-semibold">Date Signed Up:</strong> {{ moment.unix(user.date_created/1000).format('LLLL') }}</p>
                <p><strong class="font-semibold">Endorsed By:</strong> {{ user.endorser }}</p>
                <p v-if="user.reviewers.caap != 'inactive'"><strong class="font-semibold">Purchase Date:</strong> {{ (user.caap_data) ? moment.unix(user.caap_data.purchase_date/1000).format('LLLL') : ''}}</p>
                <!-- <p v-if="user.reviewers.caap != 'inactive'"><strong class="font-semibold">Purchase Price:</strong> {{ (user.caap_data) ? user.caap_data.gcash_refno : '' }}</p> -->
                <p v-if="user.reviewers.caap != 'inactive'"><strong class="font-semibold">GCash Ref. No.:</strong> {{ (user.caap_data) ? user.caap_data.gcash_refno : '' }}</p>
              </div>
              <div v-if="user.reviewers.caap == 'paid'" class="flex justify-between space-x-2 px-4 bg-gray-50 py-3">
                <button @click="revokePurchase(user, 'caap')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                    <HandThumbDownIcon class="w-4 h-4"/>
                    <span>Revoke CAAP</span>
                </button>
                
                <button @click="confirmUserPurchase(user, 'caap')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                    <HandThumbUpIcon class="w-4 h-4"/>
                    <span>Confirm CAAP</span>
                </button>
              </div>
              <div v-else class="flex justify-between space-x-2 px-4 bg-gray-50 py-3">
                <button v-if="user.reviewers.caap == 'active'" @click="revokePurchase(user, 'caap')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                  <ArchiveBoxXMarkIcon class="w-4 h-4"/>
                  <span>Revoke CAAP</span>
                </button>
                <button v-else-if="user.reviewers.caap == 'inactive'" @click="purchaseForUser(user, 'caap')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                  <GiftIcon class="w-4 h-4"/>
                  <span>Purchase CAAP</span>
                </button>
              </div>
            </li>
          </ul>
          <!-- CAAP Lite -->
          <ul v-if="revToShow == 'caap_lite'">
            <li v-for="user in todaysUsers" :key="user.id" class="border border-gray-300 text-xs sm:text-sm flex flex-col ">
              <div class="px-5 py-4">
                <p><strong class="font-semibold">Full Name:</strong> {{ user.firstname }} {{ user.lastname }}</p>
                <p><strong class="font-semibold">Email:</strong> {{ user.email }}</p>
                <p><strong class="font-semibold">Date Signed Up:</strong> {{ moment.unix(user.date_created/1000).format('LLLL') }}</p>
                <p><strong class="font-semibold">Endorsed By:</strong> {{ user.endorser }}</p>
                <p v-if="user.reviewers.caap_lite != 'inactive' && user.reviewers.caap_lite != ''"><strong class="font-semibold">Purchase Date:</strong> {{ (user.caap_lite_data) ? moment.unix(user.caap_lite_data.purchase_date/1000).format('LLLL') : ''}}</p>
                <p v-if="user.reviewers.caap_lite != 'inactive' && user.reviewers.caap_lite != ''"><strong class="font-semibold">GCash Ref. No.:</strong> {{ (user.caap_lite_data) ? user.caap_lite_data.gcash_refno : '' }}</p>
              </div>
              <div v-if="user.reviewers.caap_lite == 'paid'" class="flex justify-between space-x-2 px-4 bg-gray-50 py-3">
                <button @click="revokePurchase(user, 'caap_lite')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                    <HandThumbDownIcon class="w-4 h-4"/>
                    <span>Revoke CAAP Lite</span>
                </button>
                
                <button @click="confirmUserPurchase(user, 'caap_lite')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                    <HandThumbUpIcon class="w-4 h-4"/>
                    <span>Confirm CAAP Lite</span>
                </button>
              </div>
              <div v-else class="flex justify-between space-x-2 px-4 bg-gray-50 py-3">
                <button v-if="user.reviewers.caap_lite == 'active'" @click="revokePurchase(user, 'caap_lite')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                  <ArchiveBoxXMarkIcon class="w-4 h-4"/>
                  <span>Revoke CAAP Lite</span>
                </button>
                <button v-else-if="user.reviewers.caap_lite == 'inactive'" @click="purchaseForUser(user, 'caap_lite')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                  <GiftIcon class="w-4 h-4"/>
                  <span>Purchase CAAP Lite</span>
                </button>
              </div>
            </li>
          </ul>
          <!-- CAAP Plus -->
          <ul v-if="revToShow == 'caap_plus'">
            <li v-for="user in todaysUsers" :key="user.id" class="border border-gray-300 text-xs sm:text-sm flex flex-col ">
              <div class="px-5 py-4">
                <p><strong class="font-semibold">Full Name:</strong> {{ user.firstname }} {{ user.lastname }}</p>
                <p><strong class="font-semibold">Email:</strong> {{ user.email }}</p>
                <p><strong class="font-semibold">Date Signed Up:</strong> {{ moment.unix(user.date_created/1000).format('LLLL') }}</p>
                <p><strong class="font-semibold">Endorsed By:</strong> {{ user.endorser }}</p>
                <p v-if="user.reviewers.caap_plus != 'inactive' && user.reviewers.caap_plus != ''"><strong class="font-semibold">Purchase Date:</strong> {{ (user.caap_plus_data) ? moment.unix(user.caap_plus_data.purchase_date/1000).format('LLLL') : ''}}</p>
                <p v-if="user.reviewers.caap_plus != 'inactive' && user.reviewers.caap_plus != ''"><strong class="font-semibold">GCash Ref. No.:</strong> {{ (user.caap_plus_data) ? user.caap_plus_data.gcash_refno : '' }}</p>
              </div>
              <div v-if="user.reviewers.caap_plus == 'paid'" class="flex justify-between space-x-2 px-4 bg-gray-50 py-3">
                <button @click="revokePurchase(user, 'caap_plus')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                    <HandThumbDownIcon class="w-4 h-4"/>
                    <span>Revoke CAAP Plus</span>
                </button>
                
                <button @click="confirmUserPurchase(user, 'caap_plus')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                    <HandThumbUpIcon class="w-4 h-4"/>
                    <span>Confirm CAAP Plus</span>
                </button>
              </div>
              <div v-else class="flex justify-between space-x-2 px-4 bg-gray-50 py-3">
                <button v-if="user.reviewers.caap_plus == 'active'" @click="revokePurchase(user, 'caap_plus')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                  <ArchiveBoxXMarkIcon class="w-4 h-4"/>
                  <span>Revoke CAAP Plus</span>
                </button>
                <button v-else-if="user.reviewers.caap_plus == 'inactive'" @click="purchaseForUser(user, 'caap_plus')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                  <GiftIcon class="w-4 h-4"/>
                  <span>Purchase CAAP Plus</span>
                </button>
              </div>
            </li>
          </ul>
          <!-- MTP -->
          <ul v-if="revToShow == 'mtp'">
            <li v-for="user in todaysUsers" :key="user.id" class="border border-gray-300 text-xs sm:text-sm flex flex-col ">
              <div class="px-5 py-4">
                <p><strong class="font-semibold">Full Name:</strong> {{ user.firstname }} {{ user.lastname }}</p>
                <p><strong class="font-semibold">Email:</strong> {{ user.email }}</p>
                <p><strong class="font-semibold">Date Signed Up:</strong> {{ moment.unix(user.date_created/1000).format('LLLL') }}</p>
                <p><strong class="font-semibold">Endorsed By:</strong> {{ user.endorser }}</p>
                <p v-if="user.reviewers.mtp != 'inactive' && user.reviewers.mtp != ''"><strong class="font-semibold">Purchase Date:</strong> {{ (user.mtp_data) ? moment.unix(user.mtp_data.purchase_date/1000).format('LLLL') : ''}}</p>
                <p v-if="user.reviewers.mtp != 'inactive' && user.reviewers.mtp != ''"><strong class="font-semibold">GCash Ref. No.:</strong> {{ (user.mtp_data) ? user.mtp_data.gcash_refno : '' }}</p>
              </div>
              <div v-if="user.reviewers.mtp == 'paid'" class="flex justify-between space-x-2 px-4 bg-gray-50 py-3">
                <button @click="revokePurchase(user, 'mtp')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                    <HandThumbDownIcon class="w-4 h-4"/>
                    <span>Revoke MTP</span>
                </button>
                
                <button @click="confirmUserPurchase(user, 'mtp')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                    <HandThumbUpIcon class="w-4 h-4"/>
                    <span>Confirm MTP</span>
                </button>
              </div>
              <div v-else class="flex justify-between space-x-2 px-4 bg-gray-50 py-3">
                <button v-if="user.reviewers.mtp == 'active'" @click="revokePurchase(user, 'mtp')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                  <ArchiveBoxXMarkIcon class="w-4 h-4"/>
                  <span>Revoke MTP</span>
                </button>
                <button v-else-if="user.reviewers.mtp == 'inactive'" @click="purchaseForUser(user, 'mtp')" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                  <GiftIcon class="w-4 h-4"/>
                  <span>Purchase MTP</span>
                </button>
              </div>
            </li>
          </ul>
          
        </div>

      </div>

      <!-- NEW LICENSED VIEW -->
      <ul v-else-if="activeView == VIEWS[2]" class="flex flex-col space-y-4">
        <div v-if="newLicensedUsers.length == 0">
          Nothing to display
        </div>
        <li v-else v-for="user in newLicensedUsers" :key="user.id" class="border border-gray-300 text-xs sm:text-sm flex flex-col ">
          <div class="px-5 py-4">
            <p><strong class="font-semibold">Full Name:</strong> {{ user.firstname }} {{ user.lastname }}</p>
            <p><strong class="font-semibold">Email:</strong> {{ user.email }}</p>
            <p><strong class="font-semibold">Date Signed Up:</strong> {{ moment.unix(user.date_created/1000).format('LLLL') }}</p>
            <p><strong class="font-semibold">Endorsed By:</strong> {{ user.endorser }}</p>
            <p v-if="user.reviewers.caap != 'inactive'"><strong class="font-semibold">Purchase Date:</strong> {{ (user.caap_data) ? moment.unix(user.caap_data.purchase_date/1000).format('LLLL') : ''}}</p>
            <p v-if="user.reviewers.caap != 'inactive'"><strong class="font-semibold">GCash Ref. No.:</strong> {{ (user.caap_data) ? user.caap_data.gcash_refno : '' }}</p>
          </div>

          <div v-if="user.license && user.license.isLicensed && !user.license.isVerified" class="flex justify-between space-x-2 px-4 bg-gray-50 py-3">
            <button @click="revokeLicense(user)" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                <HandThumbDownIcon class="w-4 h-4"/>
                <span>Revoke</span>
            </button>
            <div class="flex space-x-2">
              <a :href="user.license.test_results" target="_blank" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                  <DocumentMagnifyingGlassIcon class="w-4 h-4"/>
                  <span>View Test Results</span>
              </a>
              <button @click="verifyLicense(user)" type="button" class="inline-flex space-x-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs sm:text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-warm-gray-500 focus:ring-offset-2">
                <HandThumbUpIcon class="w-4 h-4"/>
                <span>Verify</span>
              </button>
            </div>
            
            
          </div>
        </li>
      </ul>
    </main>

    <!-- Waiting Screen -->
    <main v-if="isInitializing" class="overflow-auto h-full w-full flex justify-center items-center">
      <Logo class="-mt-24 w-24 animate-pulse text-warm-gray-300" />
    </main>

    <!-- Notification -->
    <div v-if="isWaiting" aria-live="assertive" class="absolute pointer-events-none bottom-14 inset-0 flex items-end px-2 py-4">
      <div class="flex w-full flex-col space-y-4 items-center">
        <!--
          Notification panel, dynamically insert this into the live region when it needs to be displayed

          Entering: "transform ease-out duration-300 transition"
            From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            To: "translate-y-0 opacity-100 sm:translate-x-0"
          Leaving: "transition ease-in duration-100"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="pointer-events-auto w-auto max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="px-6 py-3">
            <div class="flex items-center justify-between">
              <p class="flex-1 text-sm text-center font-medium text-gray-900">{{ notificationMessage }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  
</template>

<script setup>

import { ref, computed } from "vue";
import moment from "moment";
import { httpsCallable } from "firebase/functions";
import { getDoc, getDocs,collection, orderBy, query, doc, updateDoc, setDoc, deleteDoc } from "firebase/firestore";
import { auth, db, functions } from '@/firebase/config.js';
import Logo from "@/_for_dash/icons/Logo";

import { subscribe} from "@/convertkit/subscribe"

import {
  HandThumbUpIcon,
  HandThumbDownIcon,
  GiftIcon, 
  ArchiveBoxXMarkIcon,
  DocumentMagnifyingGlassIcon
} from "@heroicons/vue/24/outline";

const isInitializing = ref(true);
const isWaiting = ref(false);
const notificationMessage = ref('');


const users = ref([]);
const schools = ref([]);

const revToShow = ref('caap')

const purchasedUsers = computed(() => {
  return users.value.filter( e => (e.reviewers.caap == 'paid') ) 
})

const purchasedUsersCaapLite = computed(() => {
  return users.value.filter( e => (e.reviewers.caap_lite == 'paid') ) 
})

const purchasedUsersCaapPlus = computed(() => {
  return users.value.filter( e => (e.reviewers.caap_plus == 'paid') ) 
})

const purchasedUsersMtp = computed(() => {
  return users.value.filter( e => (e.reviewers.mtp == 'paid') ) 
})

const todaysUsers = computed(() => {
  return users.value.filter( e => (e.date_created > moment().startOf('day').format("x")) ) 
})

const newLicensedUsers = computed(() => {
  return users.value.filter( e => (e.license && e.license.isLicensed && !e.license.isVerified && !e.license.isRevoked) ) 
})

const VIEWS = [
    'New Buyers',
    'Today\'s Signups',
    'New Licensed',
]
const activeView = ref(VIEWS[0])

/**
 * Directly fetch all schools from the Firebase database
 */
 const fetchAllUsers = async () => {
    return new Promise(async (resolve, reject) => {
        getDocs( query(collection(db, "users"), orderBy("date_created", "desc")))
            .then((querySnapshot) => { 
                if(querySnapshot.size > 0){
                    var _users = [];
                    querySnapshot.forEach((doc) => {
                        let data = doc.data()
                        data.id = doc.id
                        _users.push(data)
                    });
                    resolve(_users)
                }else{
                    console.log("Query returned no results!")
                    resolve(_users)
                }
            })
            .catch((error) => {
                console.error("Error retreiving schools list from DB");
                reject(new Error(`Error getting user document: ${error}`))
            });
    })
}

/**
 * Directly fetch all schools from the Firebase database
 */
const fetchAllSchools = async () => {
    return new Promise(async (resolve, reject) => {
      getDocs( query(collection(db, "schools")))
        .then((querySnapshot) => { 
            if(querySnapshot.size > 0){
              var _schools = [];
              querySnapshot.forEach((doc) => {
              let data = doc.data()
              data.id = doc.id
              _schools.push(data)
              });
              resolve(_schools)
            }else{
              console.log("Query returned no results!")
              resolve(_schools)
            }
        })
        .catch((error) => {
          console.error("Error retreiving schools list from DB");
          reject(new Error(`Error getting user document: ${error}`))
        });
    })
}

const confirmUserPurchase = async (user, rev) => {

  isWaiting.value = true;
  /**
   * First step is to purchase the reviewer for the user via
   * firebase function. This is for setting the user claims
  */
  notificationMessage.value = `Activating ${rev.toUpperCase()} reviewer claims...`;
  let funcPayload = {};
  let keysToremove = [];
  switch (rev) {
    case 'caap':
        funcPayload = {caap: false};
        keysToremove.push('caapExpAt')
      break;
    case 'caap_lite':
        funcPayload = {caap_lite: true};
        break;
    case 'caap_plus':
          funcPayload = {caap_plus: false};
          keysToremove.push('plusExpAt')
      break;
    case 'mtp':
        funcPayload = {mtp: false};
        keysToremove.push('mtpExpAt')
      break;
  }

  const updateUserClaimsFbFunc = httpsCallable(functions, "updateUserClaims");
  const updateUserClaimsResponse = await updateUserClaimsFbFunc({
    email: user.email,
    payload: funcPayload,
    toRemoveKeys: keysToremove,
  })

  if(updateUserClaimsResponse.data.success){

    /**
     * Update the User's Reviewer information
     */
    let _data;
    let _reviewers;
    let _user_rev_data;

    switch (rev) {
      case 'caap':
          // update the caap_data
          _user_rev_data = user.caap_data;
          _user_rev_data.activateLater = _user_rev_data.activateLater ? _user_rev_data.activateLater : false;
          _user_rev_data.activation_date = (_user_rev_data.activateLater) ? null : new Date();
          _user_rev_data.expiration_date = (_user_rev_data.activateLater) ? null : getReviewerExpiry();
          _data = {caap_data: _user_rev_data};
          // update caap in reviewers object to ready
          _reviewers = {
            'reviewers.caap': 'ready'
          } 

        break;
      case 'caap_lite':
          // update the caap_lite_data
          _user_rev_data = user.caap_lite_data;
          _data = {caap_lite_data: _user_rev_data};
          // update caap_lite in reviewers object to active
          _reviewers = {
            'reviewers.caap_lite': 'active'
          } 

        break;
      //Update Jun 27, 2024: CAAP Plus reviewer has now the "active later".
      case 'caap_plus':
            // update the caap_plus_data
            _user_rev_data = user.caap_plus_data;
            _user_rev_data.activateLater = true;
            _user_rev_data.activation_date = null;
            _user_rev_data.expiration_date = null;
            _data = {caap_plus_data: _user_rev_data};
            // update caap_lite in reviewers object to ready
            _reviewers = {
              'reviewers.caap_plus': 'ready'
            } 
        break;
      //Update Jun 25, 2024: MTP reviewer has now the "active later".
      case 'mtp':
            // update the mtp_data
          _user_rev_data = user.mtp_data;
          _user_rev_data.activateLater = true;
          _user_rev_data.activation_date = null;
          _user_rev_data.expiration_date = null;
          _data = {mtp_data: _user_rev_data};
          // update mtp in reviewers object to ready
          _reviewers = {
              'reviewers.mtp': 'ready'
            }
        break;
    }

    notificationMessage.value = `Updating User ${rev.toUpperCase()} data...`;

    console.log(_reviewers, _data);

    updateDoc( doc(db, "users", user.id), { ..._reviewers, ..._data })
      .then(() => { 
        console.log(`User's ${rev.toUpperCase()} information has been successfully updated.`) 
        
        notificationMessage.value = '✅ Purchase is now confirmed.';

        
        /**
         * Send confirmation email to the user
         */
        let reviewerName = '';
        let durationInWords = '';
        let originalAmount = 0;
        let discount = 0;
        let paidAmount = 0;

        switch (rev) {
          case 'caap':
              reviewerName = 'CAAP Knowledge';
              durationInWords = user.caap_data.durationInWords;
              discount = user.caap_data.couponDiscount ? parseFloat(user.caap_data.couponDiscount) : 0;
              originalAmount = parseFloat(user.caap_data.finalPrice) + discount;
              paidAmount = parseFloat(user.caap_data.finalPrice);
            break;
          case 'caap_plus':
              reviewerName = 'CAAP Practical';  
              durationInWords = user.caap_plus_data.durationInWords;
              discount = user.caap_plus_data.couponDiscount ? parseFloat(user.caap_plus_data.couponDiscount) : 0;
              originalAmount = parseFloat(user.caap_plus_data.finalPrice) + discount;
              paidAmount = parseFloat(user.caap_plus_data.finalPrice);
            break;
          case 'mtp':
              reviewerName = 'Entrance Exam';  
              durationInWords = user.mtp_data.durationInWords;
              discount = user.mtp_data.couponDiscount ? parseFloat(user.mtp_data.couponDiscount) : 0;
              originalAmount = parseFloat(user.mtp_data.finalPrice) + discount;
              paidAmount = parseFloat(user.mtp_data.finalPrice);
            break;
        }    

        const sendInvoiceEmailFbFunc = httpsCallable(functions, "sendEmailConfirmPurchase");
        const sendInvoiceEmail = sendInvoiceEmailFbFunc({
          email: user.email,
          name: user.firstname,
          reviewerName: reviewerName,
          durationInWords: durationInWords,
          originalAmount: originalAmount,
          discount: discount,
          paidAmount: paidAmount
        })
        
        setTimeout(() => {
          refetchData();
        }, 2000);

      })
      .catch((error) => { 
        console.error(`Error updating user ${rev.toUpperCase()} information: `, error);
      });

  }else{
    console.error(`An error happened during ${rev.toUpperCase()} reviewer purchase.`)
  }
}

const getReviewerExpiry = () => {
  let durationInDays = 180; // 30 days default. Ideally, it should be set by user
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + durationInDays);
  // const expiryTimestamp = Timestamp.fromDate(currentDate)
  return currentDate
}

/**
 * 
 * @TODO: 
 * This is an abomination. Please transform to awaits using new Firebase functions
 * istead of .then()
 * 
 */
const verifyLicense = async (user) => {

  isWaiting.value = true;
  notificationMessage.value = 'Confirming license information...';
  
  console.log("User's license information will now be updated.") 
  
  let _license = user.license;
  _license.isVerified = true;
  _license.isRevoked = false;

  let _skillpoints = user.skillpoints + 2000;

  /**
   * Update User
   */
  updateDoc( doc(db, "users", user.id), { 
        license: _license,
        skillpoints: _skillpoints
      })
      .then(async () => { 
        console.log("User's license information has been successfully updated.") 
        
        notificationMessage.value = 'Upating leaderboard points...';
        
        /**
         * 
         * Global Leaderboard +2000
         * 
         */

        let user_global_leaderboard = await fetchUserInGlobalLeaderboard(user.id)
        let _global_skillpoints = user_global_leaderboard.skillpoints + 2000;
        updateDoc( doc(db, "leaderboard", user.id), { 
              skillpoints: _global_skillpoints,
              isVerified: true
            })
            .then(async () => { 
              console.log("User's global skillpoints has been successfully updated.") 
              
              notificationMessage.value = 'Upating season2 leaderboard points...';
              
              /**
              * 
              * S2 Leaderboard +2000
              * 
              */

              let user_s2_leaderboard = await fetchUserInS2Leaderboard(user.id)
              
              if(user_s2_leaderboard){
                let _s2_skillpoints = user_s2_leaderboard.skillpoints + 2000;
                updateDoc( doc(db, "s2leaderboard", user.id), { 
                      skillpoints: _s2_skillpoints,
                      isVerified: true
                    })
                    .then(() => { 
                      console.log("User's s2 skillpoints has been successfully updated.") 
                      
                      notificationMessage.value = '✅ License is now confirmed.';
                      
                      setTimeout(() => {
                        refetchData();
                      }, 2000);
  
                    })
                    .catch((error) => { 
                      console.error("Error updating user license information: ", error);
                    });
              }else{
                notificationMessage.value = '✅ License is now confirmed.';
                      
                setTimeout(() => {
                  refetchData();
                }, 2000);
              }

            })
            .catch((error) => { 
              console.error("Error updating user license information: ", error);
            });

      })
      .catch((error) => { 
        console.error("Error updating user license information: ", error);
      });
  
}

const fetchUserInS2Leaderboard = async (uid) => {
  return new Promise(async (resolve, reject) => {

    const docRef = doc(db, "s2leaderboard", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let data = docSnap.data()
      data.id = docSnap.id;
      resolve(data)
    } else {
      // doc.data() will be undefined in this case
      console.error("No such document! User not found in S2 Leaderboard!")
      resolve(null)
    }
  })
}

const fetchUserInGlobalLeaderboard = async (uid) => {
  return new Promise(async (resolve, reject) => {

    const docRef = doc(db, "leaderboard", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let data = docSnap.data()
      data.id = docSnap.id;
      resolve(data)
    } else {
      // doc.data() will be undefined in this case
      console.error("No such document!!!")
    }
  })
}

const revokeLicense = async (user) => {

  isWaiting.value = true;
  notificationMessage.value = 'Confirming license information...';

  console.log("User's license information will now be updated.") 

  let _license = user.license;
  _license.isVerified = false;
  _license.isRevoked = true;

  // let _skillpoints = user.skillpoints - 2000;

  /**
   * Update User
   */
  updateDoc( doc(db, "users", user.id), { 
        license: _license,
        // skillpoints: _skillpoints
      })
      .then(async () => { 
        console.log("User's license information has been successfully updated.") 
        
        notificationMessage.value = '✅ License is now revoked.';
                    
        setTimeout(() => {
          refetchData();
        }, 2000);

        
        // removing this code... this is only applicable when verifying

        // notificationMessage.value = 'Upating leaderboard points...';
        
        /**
         * 
         * Global Leaderboard +2000
         * 
         */

        // let user_global_leaderboard = await fetchUserInGlobalLeaderboard(user.id)

        // let _global_skillpoints = user_global_leaderboard.skillpoints - 2000;
        // updateDoc( doc(db, "leaderboard", user.id), { 
        //       skillpoints: _global_skillpoints,
        //       isVerified: true
        //     })
        //     .then(async () => { 
        //       console.log("User's global skillpoints has been successfully updated.") 
              
        //       notificationMessage.value = 'Upating season2 leaderboard points...';
              
        //       /**
        //       * 
        //       * S2 Leaderboard +2000
        //       * 
        //       */

        //       let user_s2_leaderboard = await fetchUserInS2Leaderboard(user.id)
        //       let _s2_skillpoints = user_s2_leaderboard.skillpoints - 2000;
        //       updateDoc( doc(db, "s2leaderboard", user.id), { 
        //             skillpoints: _s2_skillpoints,
        //             isVerified: true
        //           })
        //           .then(() => { 
        //             console.log("User's s2 skillpoints has been successfully updated.") 
                    
        //             notificationMessage.value = '✅ License is now revoked.';
                    
        //             setTimeout(() => {
        //               refetchData();
        //             }, 2000);

        //           })
        //           .catch((error) => { 
        //             console.error("Error updating user license information: ", error);
        //           });

        //     })
        //     .catch((error) => { 
        //       console.error("Error updating user license information: ", error);
        //     });

      })
      .catch((error) => { 
        console.error("Error updating user license information: ", error);
      });

}


  /**
   * Purchase CAAP reviewer for the user
   */
  const purchaseForUser = (user, rev) => {

    isWaiting.value = true;
    notificationMessage.value = `Purchasing ${rev.toUpperCase()} for user...`;
    
    let _data = {
      purchase_date: moment().valueOf().toString(),
      gcash_refno: 'PURCHASED_VIA_ADMIN'
    }

    let _reviewers ;
    let dataToUpdate;

    switch (rev) {
      case 'caap':
        _reviewers = {
          caap: 'paid',
          caap_lite: user.reviewers.caap_lite ? user.reviewers.caap_lite : 'inactive',
          caap_plus: user.reviewers.caap_plus ? user.reviewers.caap_plus : 'inactive',
          mtp: user.reviewers.mtp ? user.reviewers.mtp : 'inactive',
        }
        dataToUpdate = {caap_data: _data}
        break;
      case 'caap_lite':
        _reviewers = {
          caap: user.reviewers.caap ? user.reviewers.caap : 'inactive',
          caap_lite: 'paid',
          caap_plus: user.reviewers.caap_plus ? user.reviewers.caap_plus : 'inactive',
          mtp: user.reviewers.mtp ? user.reviewers.mtp : 'inactive',
        }
        dataToUpdate = {caap_lite_data: _data}
        break;
      case 'caap_plus':
        _reviewers = {
          caap: user.reviewers.caap ? user.reviewers.caap : 'inactive',
          caap_lite: user.reviewers.caap_lite ? user.reviewers.caap_lite : 'inactive',
          caap_plus: 'paid',
          mtp: user.reviewers.mtp ? user.reviewers.mtp : 'inactive',
        }
        dataToUpdate = {caap_plus_data: _data}
        break;
      case 'mtp':
        _reviewers = {
          caap: user.reviewers.caap ? user.reviewers.caap : 'inactive',
          caap_lite: user.reviewers.caap_lite ? user.reviewers.caap_lite : 'inactive',
          caap_plus: user.reviewers.caap_plus ? user.reviewers.caap_plus : 'inactive',
          mtp: 'paid',
        }
        dataToUpdate = {mtp_data: _data}
        break;
    }

    updateDoc( doc(db, "users", user.id), { 
        reviewers: _reviewers,
        ...dataToUpdate
      })
      .then(async () => { 
        notificationMessage.value = `✅ User has now purchased ${rev.toUpperCase()}.`;

        setTimeout(() => {
          refetchData();
        }, 2000);

      })
      .catch((error) => { console.error("Error updating user data: ", error)  });

}


/**
 * Revoke someone's purchase
 */
const revokePurchase = async (user,rev) => {
  
  isWaiting.value = true;
  notificationMessage.value = `Revoking User\'s ${rev.toUpperCase()} access...`;

  //identify which reviewer to revoke and then initialize the payload to send to firebase function
  let funcPayload = {};
  let keysToremove = [];
  switch (rev) {
    case 'caap':
        funcPayload = {caap: false};
        keysToremove.push('caapExpAt');
      break;
    case 'caap_lite':
        funcPayload = {caap_lite: false};
        break;
    case 'caap_plus':
          funcPayload = {caap_plus: false};
          keysToremove.push('plusExpAt');
      break;
    case 'mtp':
        funcPayload = {mtp: false};
        keysToremove.push('mtpExpAt');
      break;
  }

  const updateUserClaimsFbFunc = httpsCallable(functions, "updateUserClaims");
  const updateUserClaimsResponse = await updateUserClaimsFbFunc({
    email: user.email,
    payload: funcPayload,
    toRemoveKeys: keysToremove,
  })

  if(updateUserClaimsResponse.data.success){

    let _data;
    let _reviewers;

    switch (rev) {
      case 'caap':
          _data = {caap_data : {
            purchase_date: '',
            gcash_refno: ''
          }}
    
          _reviewers = {
            'reviewers.caap': 'inactive'
          }  
        break;
      case 'caap_lite':
          _data = {caap_lite_data : {
            purchase_date: '',
            gcash_refno: ''
          }}
      
          _reviewers = {
            'reviewers.caap_lite': 'inactive'
          } 
        break;
      case 'caap_plus':
          _data = {caap_plus_data : {
            purchase_date: '',
            gcash_refno: ''
          }}
    
          _reviewers = {
            'reviewers.caap_plus': 'inactive'
          } 
        break;
      case 'mtp':
          _data = {mtp_data : {
            purchase_date: '',
            gcash_refno: ''
          }}

          _reviewers = {
            'reviewers.mtp': 'inactive'
          } 

        break;
    }


    console.log(user)

    notificationMessage.value = `Updating User ${rev.toUpperCase()} data...`;
    updateDoc( doc(db, "users", user.id), { ..._reviewers, ..._data })
      .then(() => { 
        console.log(`User's ${rev.toUpperCase()} information has been successfully updated.`) 
        
        notificationMessage.value = '✅ Purchase has been revoke.';
        
        setTimeout(() => {
          refetchData();
        }, 2000);

      })
      .catch((error) => { 
        console.error(`Error updating user ${rev.toUpperCase()} information: `, error);
      });


    }else{
      console.error('An error happened during CAAP reviewer revoke.')
    }

}

const refetchData = async () => {
  notificationMessage.value = "Refreshing data..."
  isWaiting.value = true;
  users.value = await fetchAllUsers();
  schools.value = await fetchAllSchools();
  isWaiting.value = false;
}

const initialize = async () => {

  console.log(moment.unix(1691310648110/1000).format('LLLL'));

  isInitializing.value = true;
  users.value = await fetchAllUsers();
  schools.value = await fetchAllSchools();
  isInitializing.value = false;
}

initialize();

// console.log(moment().valueOf().toString())
// let now = moment();
// console.log('now ' + now.toString())
// console.log('start ' + now.startOf('day').toString())
// console.log('end ' + now.endOf('day').toString())
// console.log(moment().startOf('day').format("x"))

</script>
<style>
</style>