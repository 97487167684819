<template>
  
  <div>
    <div class="flex justify-center mt-10 lg:p-4">
      <div class="shadow rounded overflow-hidden w-full lg:w-5/6 border dark:border-warm-gray-700">

        <div class="p-4 flex justify-between items-center border-b bg-warm-gray-100 w-full dark:bg-warm-gray-700 dark:border-warm-gray-700">
          <div class="flex justify-start items-center">
            <HomeIcon class="h-6 w-6" />
            <ChevronRightIcon class="h-4 w-4 ml-2" />
            <span class="ml-1">Subtopics</span>
            <template v-if="questionnaires.length > 0">
              <ChevronRightIcon class="h-4 w-4 ml-2" />
              <span class="ml-1">{{questionnaires[currentSelection.subtopic].subtopic}}</span>
              <template v-if="questionnaires[currentSelection.subtopic].data.questions.length > 0">
                <ChevronRightIcon class="h-4 w-4 ml-2" />
                <span class="ml-1">Question {{questionnaires[currentSelection.subtopic].data.questions[currentSelection.question].id}}</span>
              </template>
            </template>
          </div>

          <div>
            <button @click="openSearchModal" class="text-warm-gray-500 hover:text-warm-gray-700 hover:bg-warm-gray-300 dark:text-warm-gray-400 dark:hover:text-warm-gray-200 dark:hover:bg-warm-gray-800 rounded-full p-1 mr-2">
              <MagnifyingGlassIcon class="h-4 w-4"/>
            </button>
            <button @click="getQuestionnaires" class=" rounded-full bg-warm-gray-200 p-1 hover:bg-warm-gray-300 focus:bg-warm-gray-300 focus:outline-none">
              <RefreshIcon class="h-4 w-4 text-warm-gray-500" />
            </button>
          </div>
        </div>

        <div class="block lg:grid lg:grid-cols-10 gap-0">
          <!-- Subtopics Col -->
          <div class="p-2 border lg:col-span-3 dark:border-warm-gray-700">
            <div class=" flex-row">
              <div class="flex justify-start p-2 border-b font-semibold dark:text-warm-gray-400 dark:border-warm-gray-700">
                 <FolderIcon class="h-6 w-6 mr-2" />
                 Subtopics
              </div>

              <div v-if="!isGettingQuestionnaires" class="pb-2 my-2">
                <div class="flex justify-between items-center">
                  <a href="#" @click="showAddSubtopic" class="flex justify-start items-center text-yellow-600 font-bold">
                    <MinusSmIcon v-if="addNewSubtopicShow" class="h-6 w-6" /> 
                    <PlusSmIcon v-else class="h-6 w-6" /> 
                    Add New
                  </a>
                  <a v-if="questionnaires.length > 0" href="#" class="ml-1" @click="deleteSubtopic">
                    <TrashIcon class="h-4 w-4 text-warm-gray-700 dark:text-warm-gray-300" />
                  </a>
                </div>
                <!-- Add New Subtopic Form -->
                <div v-if="addNewSubtopicShow" class="bg-warm-gray-300 p-2 rounded-sm dark:bg-warm-gray-700">
                  <label class="block text-left xl:max-width: 400px;">
                    <span class="text-gray-700 flex justify-between items-center text-sm dark:text-warm-gray-300">
                      Select Subtopic:
                    </span>
                    <div class="flex items-center border border-yellow-600 mt-1 bg-yellow-600">
                      <select v-model="newSubtopic" class=" w-3/4 form-select block p-1 focus:outline-none appearance-none text-warm-gray-600 dark:text-warm-gray-800" :disabled="isProcessing">
                          <option v-for="(subtopic,i) in subtopics" :key="i" :value="{id: subtopic.id, name: subtopic.data.name}">{{subtopic.data.name}}</option>
                      </select>
                      <button @click="addQuestionnaire" :class="[isProcessing ? 'cursor-wait':'', 'w-1/4 bg-yellow-600 text-white rounded-sm h-full p-1 flex justify-center items-center']" :disabled="isProcessing">
                        <SpinningIcon v-if="isProcessing" class="h-4 w-4 mr-1"></SpinningIcon>
                        Add 
                      </button>
                    </div>
                    <span v-if="error.for == 'add-subtopic'" class=" text-red-700 text-xs">{{error.message}}</span>
                  </label>
                </div>
              </div>
              <div v-else class="flex justify-between items-center pb-2 my-2">
                <span class="h-4 bg-yellow-600 w-3/5 animate-pulse opacity-25"></span>
                <span class="h-4 w-4 bg-warm-gray-200 animate-pulse dark:bg-warm-gray-700"></span>
              </div>
              <!-- List of Subtopics -->
              <div :class="[questionnaires.length > 17 ? 'overflow-y-scroll' : '', 'lg:min-h-550-px max-h-550-px']">
                <ul v-if="!isGettingQuestionnaires">
                  <li v-for="(questionnaire, i) in questionnaires" 
                      :key="i" 
                      @click="selectSubtopic(i)" 
                      :class="[currentSelection.subtopic == i ? 'bg-warm-gray-200 dark:bg-warm-gray-800':'', 'py-1 pl-8 pr-2 flex justify-between items-center hover:bg-warm-gray-200 cursor-pointer dark:hover:bg-warm-gray-800']">
                    {{questionnaire.data.name}}
                    <ChevronRightIcon v-if="currentSelection.subtopic == i" class="h-4 w-4" />
                  </li>
                </ul>
                <ul v-else class="animate-pulse mr-4">
                  <li v-for="i in 10" :key="i" class="mx-2 my-4 bg-warm-gray-200 h-4 w-full dark:bg-warm-gray-800"></li>
                </ul>
              </div>
            </div>
          </div>

          <!-- Questions Col -->
          <div class="p-2 border lg:col-span-3 dark:border-warm-gray-700">
            <div class=" flex-row">
              <div class="flex justify-start p-2 border-b font-semibold dark:text-warm-gray-400 dark:border-warm-gray-700">
                 <CollectionIcon class="h-6 w-6 mr-2" />
                 {{questionnaires.length > 0 ? questionnaires[currentSelection.subtopic].subtopic : 'List Questions'}}
              </div>
              <template v-if="!isGettingQuestionnaires">
                <div v-if="questionnaires.length > 0">
                  <div class="pb-2  my-2 flex justify-between items-center">
                      <a href="#" @click="openModal" class="flex justify-start items-center text-yellow-600 font-bold">
                        <PlusSmIcon class="h-6 w-6" /> 
                        Add Question
                      </a>
                      <a v-if="questionnaires.length > 0 && questionnaires[currentSelection.subtopic].data.questions.length > 0" href="#" class="ml-1" @click="delQuestion">
                        <TrashIcon class="h-4 w-4 text-warm-gray-700 flex items-center dark:text-warm-gray-300" />
                      </a>
                  </div>
                  <!-- List of Questions -->
                  <div :class="[questionnaires[currentSelection.subtopic].data.questions.length > 17 ? 'overflow-y-scroll' : '', 'lg:min-h-550-px max-h-550-px']">
                    <ul>
                      <li v-for="(question,i) in questionnaires[currentSelection.subtopic].data.questions"
                        :key="i"
                        @click="selectQuestion(i)"
                        :class="[currentSelection.question == i ? 'bg-warm-gray-200 dark:bg-warm-gray-800':'','py-1 pl-8 flex justify-between items-center hover:bg-warm-gray-200 cursor-pointer dark:hover:bg-warm-gray-800']">
                        Question {{question.id}}
                        <ChevronRightIcon v-if="currentSelection.question == i" class="h-4 w-4" />
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
              <!-- Loading Template Only -->
              <template v-else>
                 <div class="pb-2  my-2 flex justify-between items-center">
                    <span class="h-4 bg-yellow-600 w-3/5 animate-pulse opacity-25"></span>
                    <span class="h-4 w-4 bg-warm-gray-200 animate-pulse dark:bg-warm-gray-700"></span>
                </div>
                <div class="lg:min-h-550-px max-h-550-px">
                  <ul class="animate-pulse mr-4">
                    <li v-for="i in 3" :key="i" class="mx-2 my-4 bg-warm-gray-200 h-4 w-full dark:bg-warm-gray-800"></li>
                  </ul>
                </div>
              </template>
            </div>
          </div>

          <!-- Question Col -->
          <div class="p-2 border lg:col-span-4 dark:border-warm-gray-700">
            <div class=" flex-row">
              <div class="flex justify-start p-2 border-b font-semibold dark:text-warm-gray-400 dark:border-warm-gray-700">
                 <DocumentTextIcon class="h-6 w-6 mr-2" />
                 Question {{questionnaires.length > 0 && questionnaires[currentSelection.subtopic].data.questions.length > 0 ? questionnaires[currentSelection.subtopic].data.questions[currentSelection.question].id : ''}}
              </div>
              <template v-if="!isGettingQuestionnaires">
                <div v-if="questionnaires.length > 0 && questionnaires[currentSelection.subtopic].data.questions.length > 0">
                  <div class="pb-2 my-2 flex justify-start">
                    <a href="#" @click="openUpdateForm" class="flex justify-start items-center text-yellow-600 font-bold">
                      <PencilIcon class="h-5 w-5 mr-2" /> 
                      Update
                    </a>
                  </div>
                  <!-- Question info -->
                  <div class="lg:min-h-550-px max-h-550-px overflow-y-scroll">
                    <div ref="questiondiv">

                      <div v-if="questionnaires[currentSelection.subtopic].data.questions[currentSelection.question].study">
                        <!-- wrapping this with an if block because String.replace() will throw an error if Object.study is null -->
                        <template v-if="questionnaires[currentSelection.subtopic].data.questions[currentSelection.question].study.replace(/<[^>]*>/g, '')">
                          <div class="bg-warm-gray-200 py-1 px-4 text-warm-gray-500 font-semibold dark:bg-warm-gray-700 dark:text-warm-gray-300">
                            Study data:
                          </div>
                          <div v-html="questionnaires[currentSelection.subtopic].data.questions[currentSelection.question].study" class="p-2 mb-8"></div>
                        </template>
                      </div>

                      <template v-if="
                        questionnaires[currentSelection.subtopic].data.questions[currentSelection.question].question ||
                        questionnaires[currentSelection.subtopic].data.questions[currentSelection.question].questionHtml.replace(/<[^>]*>/g, '') ||
                        questionnaires[currentSelection.subtopic].data.questions[currentSelection.question].choices.length > 0
                      ">
                        <div class="bg-warm-gray-200 py-1 px-4 text-warm-gray-500 font-semibold dark:bg-warm-gray-700 dark:text-warm-gray-300">
                          Question:
                        </div>
                        <template v-if="curRevId == 'caap'">
                          <div class="p-2 whitespace-pre-wrap">
                            {{questionnaires[currentSelection.subtopic].data.questions[currentSelection.question].question}}
                          </div>
                          <div v-show="questionnaires[currentSelection.subtopic].data.questions[currentSelection.question].hasImage" class="m-2">
                            <span v-if="isLoadingImage" class=" text-yellow-600 flex items-center justify-start">
                              <SpinningIcon class="w-4 h-4 mr-2" />
                              Loading image...
                            </span>
                            <img v-show="!isLoadingImage" :src="questionnaires[currentSelection.subtopic].data.questions[currentSelection.question].imageUrl" id="question-image-view-1" class="max-h-40" />
                          </div>
                        </template>
                        <template v-else>
                          <div v-html="questionnaires[currentSelection.subtopic].data.questions[currentSelection.question].questionHtml" class="p-2"></div>
                        </template>
                        <div class="bg-warm-gray-200 py-1 px-4 text-warm-gray-500 font-semibold mt-8 dark:bg-warm-gray-700 dark:text-warm-gray-300">
                          Choices:
                        </div>
                        <div>
                          <div v-for="(choice,i) in questionnaires[currentSelection.subtopic].data.questions[currentSelection.question].choices" :key="i" class="flex justify-start items-start p-2 border-b mt-2 whitespace-pre-wrap dark:border-warm-gray-700">
                            <span v-if="choice.id == questionnaires[currentSelection.subtopic].data.questions[currentSelection.question].answer" class="font-bold text-green-500 mr-2">&#10003;</span>
                            <span v-else class="font-bold text-xs text-warm-gray-600 mr-2 mt-1">&#9632;</span>
                            <span v-if="curRevId == 'caap'">{{choice.choice}}</span>
                            <span v-else v-html="choice.choiceHtml"></span>
                          </div>
                        </div>

                        <button 
                        @click="openExplanationModal"
                        class="rounded-md mt-4 justify-center text-white mb-2 w-full px-5 py-2 bg-yellow-700 hover:opacity-75 flex items-center">
                            View Explanation
                        </button>
                      </template>


                      <div class="bg-warm-gray-200 py-1 px-4 text-warm-gray-500 font-semibold mt-8 dark:bg-warm-gray-700 dark:text-warm-gray-300">
                        Tags:
                      </div>
                      <div class="py-4 flex flex-wrap gap-2">
                        <div 
                          v-for="(tag,i) in questionnaires[currentSelection.subtopic].data.questions[currentSelection.question].tags" :key="i"
                          class=" border border-warm-gray-500 py-1 px-2 rounded-full w-max"
                          :class="tag.data.hexColor ? 'text-warm-gray-50' : 'dark:bg-warm-gray-800 bg-warm-gray-100'"
                          :style="'background-color:'+ tag.data.hexColor"
                        >
                          {{ tag.data.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <!-- Template -->
              <template v-else>
                 <div class="pb-2 my-2 flex justify-start">
                  <span class="h-4 w-2/5 bg-yellow-600 animate-pulse opacity-25"></span>
                </div>
                <!-- Question info -->
                <div class="lg:min-h-550-px max-h-550-px overflow-y-scroll">
                  <div ref="questiondiv">
                  
                    <div class="bg-warm-gray-200 py-1 px-4 text-warm-gray-500 font-semibold dark:bg-warm-gray-700 dark:text-warm-gray-300">
                      Question:
                    </div>
                    <div class="p-2 animate-pulse">
                      <div class=" bg-warm-gray-200 w-full h-4 my-2 dark:bg-warm-gray-800"></div>
                      <div class=" bg-warm-gray-200 w-full h-4 my-2 dark:bg-warm-gray-800"></div>
                      <div class=" bg-warm-gray-200 w-4/5 h-4 my-2 dark:bg-warm-gray-800"></div>
                    </div>
                    <div class="bg-warm-gray-200 py-1 px-4 text-warm-gray-500 font-semibold mt-8 dark:bg-warm-gray-800 dark:text-warm-gray-300">
                      Choices:
                    </div>
                    <div v-for="i in 4" :key="i" class="p-2 border-b mt-2 flex justify-start animate-pulse dark:border-warm-gray-700">
                      <span class="font-bold text-xs text-warm-gray-600 mr-2 dark:text-warm-gray-700">&#9632;</span>
                      <span class="h-4 w-3/4 bg-warm-gray-200 dark:bg-warm-gray-800"></span>
                    </div>
                    <div class="bg-warm-gray-200 py-1 px-4 text-warm-gray-500 font-semibold mt-8 dark:bg-warm-gray-800 dark:text-warm-gray-300">
                      Tags:
                    </div>
                    <div class="py-4 flex flex-wrap gap-2">
                        <div v-for="i in 4" :key="i" class="bg-warm-gray-200 dark:bg-warm-gray-800 py-1 px-2 rounded-full w-16 h-6 animate-pulse">
                          
                        </div>
                      </div>
                  </div>
                </div>
              </template>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>

  <ProcessingModal v-if="isProcessing" />
  
  <!-- Add / Update Question: Modal -->
  <TransitionRoot appear :show="isModalOpen" as="template">
    <Dialog as="div" @close="closeModal">
      <div class="fixed inset-0 z-10 overflow-y-auto text-sm">
        <div class="min-h-screen px-4 text-center">

          <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40" />
      
          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div class="inline-block w-full lg:w-3/5 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-warm-gray-700">
              <div class="bg-yellow-600 py-4 px-6">
                <DialogTitle
                  as="h3"
                  class="flex text-lg font-medium leading-6 pb-2 text-white"
                  >
                  {{isAdd ? 'Add new question' : `Update question ${questionnaires[currentSelection.subtopic].data.questions[currentSelection.question].id}` }}
                </DialogTitle>

                <span class=" text-warm-gray-100 text-xs">For subtopic:</span>
                <h4 class=" text-white text-md font-semibold -mt-1">
                {{questionnaires.length > 0 ? questionnaires[currentSelection.subtopic].subtopic : 'List Questions'}}
                </h4>
              </div>
              <!-- content -->
              <div class="mt-2 flex-row items-start text-warm-gray-600 px-6 py-2 dark:text-warm-gray-200">

                <!-- Error Display -->
                <div v-if="error.for == 'add-question'" class="rounded-md bg-yellow-50 p-4 mb-6">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <ExclamationIcon
                        class="h-5 w-5 text-yellow-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div class="ml-3 text-yellow-700">
                      <p class="font-bold">Error:</p>
                      <p class="text-sm">
                        <ul class="list-disc ml-5">
                          <li v-for="(error,i) in error.message" :key="i">{{error}}</li>
                        </ul>
                      </p>
                    </div>
                    <div class="ml-auto pl-3">
                      <div class="-mx-1.5 -my-1.5">
                        <button
                          @click="error.for = ''; error.message = []"
                          type="button"
                          class="inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yelow-50 focus:ring-yelow-600 ">
                          <span class="sr-only">Dismiss</span>
                          <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Question and Choices -->
                <template v-if="!isStudyMode">
                  <!-- question -->
                  <div class="flex flex-col">
                    <label for="question" class="mb-2 font-semibold">Question:</label>
                    <textarea v-if="curRevId=='caap'" class="border focus:outline-none focus:border-yellow-600 focus:shadow dark:border-warm-gray-700 dark:text-warm-gray-700 dark:bg-warm-gray-200" rows="3" v-model="questionForm.question" :disabled="isProcessing"></textarea>

                    <div v-else class="bg-white overflow-auto mt-2 rounded">
                      <div id="my-toolbar" class="bg-warm-gray-50">
                        <span class="ql-formats">
                          <button class="ql-bold"></button>
                          <button class="ql-italic"></button>
                          <button class="ql-underline"></button>
                          <button class="ql-strike"></button>
                          <select class="ql-align"></select>
                          <button class="ql-image"></button>
                        </span>
                      </div>
                      <div class=" h-56 text-warm-gray-900">
                        <QuillEditor v-model:content="questionForm.questionHtml" :modules="modules" content="html" content-type="html" theme="snow" toolbar="#my-toolbar" class="focus:outline-none focus:ring-0" placeholder="Enter question" />
                      </div>
                    </div>

                  </div>  

                  <!-- upload image (this is for caap only. legacy code.) -->
                  <div v-if="curRevId=='caap'" class="flex flex-col mt-5">
                    <div class="flex justify-start items-center">
                      <label class="mb-2 font-semibold">Image:</label>
                    </div>
                    <div class="relative overflow-hidden p-4 border-2 border-dashed rounded-md text-center text-warm-gray-300 dark:border-warm-gray-500">
                      <div v-if="!questionForm.hasImage" class="flex justify-center items-center">
                        <button @click="uploadImage()" class="flex justify-center items-center rounded bg-warm-gray-300 p-1 px-4 text-white dark:bg-warm-gray-800">
                          <PaperClipIcon class="w-4 h-4 mr-2" />
                          Attach image
                        </button>
                      </div>
                      <div v-else>
                        <div class="inset-0 mt-2 flex justify-center items-center">
                          <div v-show="!isLoadingImage">
                            <img v-if="imageFile" id="question-image" class=" max-h-52"/>
                            <img v-else :src="questionForm.imageUrl" class=" max-h-52" />
                          </div>
                          <span v-show="isLoadingImage" class=" text-yellow-600 flex items-center justify-start">
                            <SpinningIcon class="w-4 h-4 mr-2" />
                            Loading image...
                          </span>
                        </div>
                        <div class="absolute inset-0 m-2 hover:bg-warm-gray-400 hover:bg-opacity-30 opacity-0 hover:opacity-100 duration-300 z-10 flex justify-center items-center text-6xl text-white">
                            <div clas="absolute inset-0">
                                <button @click="uploadImage()" class=" text-sm py-2 px-4 mx-1 border rounded bg-opacity-80 bg-warm-gray-500">Update</button>
                                <button @click="removeImage()" class=" text-sm py-2 px-4 mx-1 border rounded bg-opacity-80 bg-warm-gray-500">Remove</button>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- choices -->
                  <div class="flex flex-col mt-5">
                    <label for="choice" class="mb-3 font-semibold">Choices:</label>
                    <div class="border-l border-dashed ml-4">

                      <div v-for="n in questionForm.choices.length" :key="n" class="-ml-3 mb-6">
                        <div class="flex justify-start">
                          <div class="h-5 w-6 text-sm font-semibold text-center bg-warm-gray-300 rounded mr-2 dark:bg-warm-gray-800">{{n}}</div>

                          <div class="w-full block justify-start">

                            <textarea v-if="curRevId == 'caap'" v-model="questionForm.choices[n-1].choice" class="w-full border focus:outline-none focus:border-yellow-600 focus:shadow dark:text-warm-gray-700 dark:bg-warm-gray-200" rows="2" :disabled="isProcessing" />
                            
                            <div v-else class="bg-white overflow-auto mt-2 rounded">
                              <div :id="'my-choice-toolbar-'+(n-1)" class="bg-warm-gray-50">
                                <span class="ql-formats">
                                  <button class="ql-bold"></button>
                                  <button class="ql-italic"></button>
                                  <button class="ql-underline"></button>
                                  <button class="ql-strike"></button>
                                  <select class="ql-align"></select>
                                  <button class="ql-image"></button>
                                </span>
                              </div>
                              <div class=" h-28 text-warm-gray-900">
                                <QuillEditor v-model:content="questionForm.choices[n-1].choiceHtml" :modules="modules" content="html" content-type="html" theme="snow" :toolbar="'#my-choice-toolbar-'+(n-1)" class="focus:outline-none focus:ring-0" placeholder="Enter choice" />
                              </div>
                            </div>
                            
                            <label class="text-xs flex justify-between items-center">
                              <div class="flex items-center">
                                <input v-model="questionForm.answer" :value="n-1" :checked="questionForm.answer == (n-1)" type="radio" name="correctChoice" :disabled="isProcessing"/>
                                <span class="ml-1">Correct</span>
                              </div>
                              <a href="#" @click="removeChoice(n-1)" class="text-xs text-warm-gray-400">remove</a>
                            </label>
                          </div>

                        </div>
                      </div>

                      <button @click="addChoice" :class="[isProcessing && ' cursor-wait' ,'-ml-3 border cursor-pointer bg-yellow-500 w-6 h-6 rounded-full flex justify-center items-center hover:bg-yellow-600 focus:bg-yellow-600 focus:outline-none']" :disabled="isProcessing">
                        <PlusIcon class="h-4 w-4 text-white"></PlusIcon>
                      </button>
                    </div>
                  </div>
                </template>

                <!-- Study Mode textarea -->
                <template v-else>
                  <label for="question" class="mb-2 font-semibold">Study data:</label>
                  <div class="bg-white overflow-auto mt-2 rounded">
                    <div id="my-toolbar-study" class="bg-warm-gray-50">
                      <span class="ql-formats">
                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>
                        <button class="ql-strike"></button>
                        <select class="ql-align"></select>
                        <button class="ql-image"></button>
                      </span>
                    </div>
                    <div class=" h-96 text-warm-gray-900">
                      <QuillEditor v-model:content="questionForm.study" :modules="modules" content="html" content-type="html" theme="snow" toolbar="#my-toolbar-study" class="focus:outline-none focus:ring-0" />
                    </div>
                  </div>
                </template>


                <!-- tags -->
                <div class="flex flex-col mt-5">
                  <div class="flex justify-start items-center">
                    <label class="mb-2 font-semibold">Tags:</label>
                  </div>
                  <div class="py-2 flex flex-wrap gap-2">
                    <div
                      v-for="tag in tags"
                      class=" border border-warm-gray-500  py-1 px-2 rounded-full  w-max"
                      :class="tag.data.hexColor ? 'text-warm-gray-50' : 'dark:bg-warm-gray-600 bg-warm-gray-100'"
                      :style="'background-color:'+ tag.data.hexColor"
                    >
                      <input type="checkbox" class="mr-1 -mt-1" v-model="selectedTags" :id="'tag_' + tag.id" :value="tag.id" >
                      <label :for="'tag_' + tag.id">{{  tag.data.name }}</label>
                    </div>
                  </div>
                </div>

              </div>
              <!-- footer -->
              <div class="mt-4 border-t p-4 flex justify-between items-center dark:border-warm-gray-600">

                <!-- Toggle A -->
                <div class="flex items-start justify-start w-full">
                  
                  <label 
                    for="toogleStudyMode"
                    class="flex items-center cursor-pointer"
                  >
                    <!-- toggle -->
                    <div class="relative">
                      <!-- input -->
                      <input id="toogleStudyMode" type="checkbox" class="sr-only" v-model="isStudyMode" />
                      <!-- line -->
                      <div class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                      <!-- dot -->
                      <div class="dot absolute w-6 h-6 bg-warm-gray-700 dark:bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                    </div>
                    <!-- label -->
                    <div class="ml-3 font-medium dark:text-warm-gray-200">
                      Study mode
                    </div>
                  </label>
                  
                </div>

                <div class="flex justify-end">
                  <button
                    type="button"
                    class="inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-warm-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    @click="cancelAddQuestion"
                    >
                    Cancel
                  </button>
                  <button
                    type="button"
                    :class="[ isProcessing && ' cursor-wait' , 'ml-2 inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-white bg-yellow-600 border border-transparent rounded-md hover:bg-yellow-700 focus:bg-yellow-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500']"
                    @click="isAdd ? addNewQuestion() : update()"
                    :disabled="isProcessing"
                    >
                    <SpinningIcon v-if="isProcessing" class="h-4 w-4 mr-1" />
                    Save
                  </button>
                </div>

              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>


  <!-- Explanation: Modal -->
  <TransitionRoot appear :show="isExplanationModalOpen" as="template">
    <Dialog as="div" @close="closeExplanationModal">
      <div class="fixed inset-0 z-10 overflow-y-auto text-sm">
        <div class="min-h-screen px-4 text-center">

          <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40" />
      
          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div class="inline-block w-full lg:w-3/5 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-warm-gray-700">
              <div class="bg-yellow-600 py-4 px-6">
                <DialogTitle
                  as="h3"
                  class="flex text-lg font-semibold leading-6 pb-2 text-white"
                  >
                  Explanation [Question {{ questionnaires[currentSelection.subtopic].data.questions[currentSelection.question].id }}]
                </DialogTitle>

                <span class=" text-warm-gray-100 text-xs">For subtopic:</span>
                <h4 class=" text-white text-md font-semibold -mt-1">
                  {{ questionnaires[currentSelection.subtopic].subtopic }}
                </h4>
              </div>
              <!-- content -->
              <div class="mt-2 flex-row items-start text-warm-gray-600 px-6 py-2 dark:text-warm-gray-200">

                <div v-if="explanationForm.docId === null" class="bg-yellow-100 border-t-4 mb-4 border-yellow-500 rounded-b text-yellow-900 px-4 py-3 shadow-md" role="alert">
                  <div class="flex">
                    <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                    <div>
                      <p class="font-bold">Explanation not found!</p>
                      <p class="text-sm">Explanation not yet for this question.</p>
                    </div>
                  </div>
                </div>

                <!-- WYSIWYG Editor -->
                <div class="bg-white overflow-auto mt-2 rounded">
                  <div id="my-toolbar" class="bg-warm-gray-50">
                    <span class="ql-formats">
                      <button class="ql-bold"></button>
                      <button class="ql-italic"></button>
                      <button class="ql-underline"></button>
                      <button class="ql-strike"></button>
                      <select class="ql-align"></select>
                      <button class="ql-image"></button>
                    </span>
                  </div>
                  <div class=" h-56 text-warm-gray-900">
                    <QuillEditor v-model:content="explanationForm.data.textHtml" :modules="modules" content="html" content-type="html" theme="snow" toolbar="#my-toolbar" class="focus:outline-none focus:ring-0" placeholder="Enter explanation here..." />
                  </div>

                </div>

                <div class=" flex justify-start align-middle gap-3 mt-2 text-warm-600 dark:text-warm-gray-300">
                  <div class="flex align-middle justify-start w-auto">
                    <HandThumbUpIcon class=" w-5 h-5 mt-1 mr-1"/>
                    <span>{{ explanationForm.data.upvotes ? explanationForm.data.upvotes : 0 }}</span>
                  </div>
                  <div class="border-l h-4 mt-1.5 border-warm-gray-500 dark:border-warm-gray-400"></div>
                  <div class="flex align-middle justify-start w-auto">
                    <HandThumbDownIcon class=" w-5 h-5 mt-1 mr-1"/>
                    <span>{{ explanationForm.data.downvotes ? explanationForm.data.downvotes : 0 }}</span>
                  </div>
                </div>

              </div>
              <!-- footer -->
              <div class="mt-4 border-t p-4 flex justify-between items-center dark:border-warm-gray-600">
                
                <div class="flex justify-start">
                  <button
                    type="button"
                    class="inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-warm-gray-400 border border-warm-gray-400 rounded-md hover:border-warm-gray-600 hover:text-warm-gray-600 dark:hover:border-warm-gray-200 dark:hover:text-warm-gray-200 focus:border-warm-gray-200 focus:text-warm-gray-200 dark:focus:border-warm-gray-200 dark:focus:text-warm-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    @click="resetVoteCounter"
                    >
                    Reset vote counter
                  </button>
                </div>

                <div class="flex justify-end">
                  <button
                    type="button"
                    class="inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-warm-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    @click="closeExplanationModal"
                    >
                    Cancel
                  </button>
                  <button
                    type="button"
                    v-if="explanationForm.docId"
                    :class="[ isProcessing && ' cursor-wait' , 'ml-2 inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500']"
                    @click="delExplanation"
                    :disabled="isProcessing"
                    >
                    Delete
                  </button>
                  <button
                    type="button"
                    :class="[ isProcessing && ' cursor-wait' , 'ml-2 inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-white bg-yellow-600 border border-transparent rounded-md hover:bg-yellow-700 focus:bg-yellow-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500']"
                    @click="saveExplanation"
                    :disabled="isProcessing"
                    >
                    Save
                  </button>
                </div>

              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Search: Modal -->
  <TransitionRoot appear :show="isSearchModalOpen" as="template">
    <Dialog as="div" @close="closeSearchModal">
      <div class="fixed inset-0 z-10 overflow-y-auto text-sm">
        <div class="min-h-screen px-4 text-center">

          <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-80" />
      
          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div class="inline-block w-full lg:w-3/5 my-8 overflow-hidden text-left align-middle transition-all transform shadow-xl">

              <div>
                <h2 class=" tracking-wider text-warm-gray-300 font-semibold text-xl ml-3 mb-2 flex justify-between">
                  <span>
                    Search question
                  </span>
                  <button tabindex="-1" @click="closeSearchModal" class="font-bold text-white">
                    <XMarkIcon class=" w-5 h-5"/>
                  </button>
                </h2>

                <div class=" relative">
                  <MagnifyingGlassIcon class=" h-6 w-6 fixed right-4 mt-4"/>
                  <input type="text" ref="searchInputField" @input="handleSearchInput" v-model="toSearch" class="p-4 pr-10 rounded-full w-full" placeholder="Search your question here">
                </div>
              </div>

              <div v-if="searchResult.length > 0 || isSearching" class="py-3 px-5 bg-warm-gray-50 dark:bg-warm-gray-700 mt-3 rounded-lg mx-3">

                <div v-if="isSearching" class=" text-warm-gray-500 italic">Seaching...</div>
                <div v-else class=" italic text-warm-gray-700 dark:text-warm-gray-300 mb-3">Question found: {{ searchResult.length }}</div>

                <button v-for="(sResult, i) in searchResult" @click="selectSearchedQuestion(sResult.sI, sResult.qI)" class="bg-warm-gray-200 dark:bg-warm-gray-800 dark:text-warm-gray-100 p-2 w-full mb-2 rounded hover:bg-warm-gray-300 dark:hover:bg-warm-gray-900 text-left">
                  <span class="text-warm-gray-800 dark:text-warm-gray-300">{{ sResult.subtopicName }}</span>
                  <br>
                  <div v-if="sResult.question.question" class="text-warm-gray-500">
                    {{ sResult.question.question }}
                  </div>
                  <div v-else v-html="sResult.question.questionHtml"></div>
                </button>

              </div>

            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  

</template>
<script>
import swal from 'sweetalert2';
import SpinningIcon from '@/components/SpinningIcon';
import ProcessingModal from '@/components/ProcessingModal';
import cloneDeep from 'lodash/cloneDeep';

import { getTags, getTagsQuestions, deleteExplanation } from '../../firebase/controllers/reviewerController';

import { QuillEditor } from '@vueup/vue-quill';
import BlotFormatter from 'quill-blot-formatter';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { addDoc, updateDoc, deleteDoc, doc, getDocs, query, where, collection } from 'firebase/firestore';
import { db } from '@/firebase/config.js';


import {
  getQuestionnaires, 
  addQuestionnaire, 
  addQuestion,
  updateQuestion,
  deleteSubtopicFromQuestionnaire,
  deleteQuestion,
  getSubtopics
} from '@/firebase/controllers/questionnaireController';

// import {
//   FolderIcon,
//   ChevronRightIcon,
//   CollectionIcon,
//   DocumentTextIcon,
//   PlusIcon,
//   RefreshIcon,
//   XIcon,
//   ExclamationIcon,
//   TrashIcon,
//   PaperClipIcon
// } from "@heroicons/vue/outline";


// import {
//   HomeIcon,
//   PlusSmIcon,
//   MinusSmIcon,
//   PencilIcon,
// } from "@heroicons/vue/solid";


import {
  FolderIcon as FolderIcon,
  ChevronRightIcon as ChevronRightIcon,
  RectangleStackIcon as CollectionIcon,
  DocumentTextIcon as DocumentTextIcon,
  PlusIcon as PlusIcon,
  ArrowPathIcon as RefreshIcon,
  // XMarkIcon as XIcon,
  ExclamationTriangleIcon as ExclamationIcon,
  TrashIcon as TrashIcon,
  PaperClipIcon as PaperClipIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  XMarkIcon
} from "@heroicons/vue/24/outline";

import {
  HomeIcon as HomeIcon,
  PlusSmallIcon as PlusSmIcon,
  MinusSmallIcon as MinusSmIcon,
  PencilIcon as PencilIcon,
  MagnifyingGlassIcon as MagnifyingGlassIcon,
} from "@heroicons/vue/24/solid";


import {
  // modal
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from '@headlessui/vue';
import { result } from 'lodash';


export default {
  data() {
    return {
      isLoadingImage: false,
      isGettingQuestionnaires: false,
      isModalOpen: false,
      isProcessing: false,
      isSortByName: true,
      isStudyMode: false,
      isExplanationModalOpen: false,
      isSearchModalOpen: false,
      curRevId: '',
      questionFormAreaHeight: 0,
      addChoicesCount: 2,
      addNewSubtopicShow: false,
      subtopics: [],
      newSubtopic: {},
      questionnaires: [],
      currentSelection:{
        subtopic: 0,
        question: 0
      },
      isAdd: true,
      imageFile: null,
      questionForm:{
        id: '',
        study: '',
        question: '',
        questionHtml: '',
        choices: [{choice:"",choiceHtml:"",id:0},{choice:"",choiceHtml:"",id:1}],
        answer: 0,
        hasImage: false,
        imageUrl: '',
        storageRefPath: '',
      },
      selectedTags: [],
      tagsQuestions: [],
      error: {
        for: '',
        message: null,
      },
      tags: [{
        "id": 1,
        "name": "news Category 0"
      },  {
        "id": 2,
        "name": "news Category 8"
      }, {
        "id": 3,
        "name": "news Category 9"
      }],
      explanationForm:{
        docId: null,
        data: {
          upvotes: 0,
          downvotes: 0,
          questionId: '',
          text: '',
          textHtml: ''
        }
      },
      modules: {
        name: 'blotFormatter',  
        module: BlotFormatter, 
        options: {/* options */},
      },
      toSearch: '', 
      searchResult: [],
      isSearching: false,
      timer: null
    }
  },
  components:{
    HomeIcon,
    PlusSmIcon,
    MinusSmIcon,
    FolderIcon,
    ChevronRightIcon,
    CollectionIcon,
    PencilIcon,
    DocumentTextIcon,
    PlusIcon,
    RefreshIcon,
    XMarkIcon,
    ExclamationIcon,
    TrashIcon,
    PaperClipIcon,
    HandThumbUpIcon,
    HandThumbDownIcon,
    MagnifyingGlassIcon,
    // modal
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    // vue components
    SpinningIcon,
    ProcessingModal,
    // vue quill
    QuillEditor
  },
  watch:{
    curRevId(newVal, oldVal){
      this.getQuestionnaires();
      getTags(this.curRevId)
      .then((res)=>{
        this.tags = res.tags;
      })
    },
  },
  updated() {
    this.curRevId = this.$route.query.r;
  },
  created() {
    // Initialize a timer variable
    this.timer = null;
  },
  async mounted() {
    if (this.$route.query.r) {
      this.curRevId = this.$route.query.r;
    }

    
  },
  methods: {
    selectSearchedQuestion(sId,qId){

      this.currentSelection.subtopic = sId;
      this.currentSelection.question = qId;

      this.closeSearchModal();

    },

    handleSearchInput(){
      this.searchResult = [];
      this.isSearching = true;
      // Clear the previous timer
      clearTimeout(this.timer);

      // check if not blank
      if (this.toSearch.trim() === '') {
        this.searchResult = [];
        this.isSearching = false;
        return;
      }

      // Set a new timer for 300 milliseconds
      this.timer = setTimeout(() => {

        this.searchQuestion(this.toSearch);
      }, 500); // Adjust debounce delay as needed
    },
    
    searchQuestion(stringToSearch){
      
      const results = [];
  
      this.questionnaires.forEach((item, subjectIndex) => {

        const questions = item.data.questions;
        const subtopicName = item.data.name;
        
        questions.forEach((questionObj, qIndex) => {

          const question = questionObj.question && questionObj.question.toLowerCase();
          const questionHtml = questionObj.questionHtml && questionObj.questionHtml.toLowerCase();
          
          if ( (question && question.includes(stringToSearch.toLowerCase())) || (questionHtml && questionHtml.includes(stringToSearch.toLowerCase()))) {
            results.push({
                subtopicName: subtopicName,
                question: questionObj,
                sI: subjectIndex,
                qI: qIndex
            });
          }

        });
      });
      
      this.isSearching = false;
      this.searchResult = results;

    },
    async getSubtopics(){
      const res = await getSubtopics(this.curRevId);
      this.subtopics = !this.isSortByName ? res.data.subtopics : res.data.subtopics.sort((a,b) => (a.data.name > b.data.name) ? 1 : ((b.data.name > a.data.name) ? -1 : 0));
    },
    async getQuestionnaires(){
      
      this.isGettingQuestionnaires = true;

    
      let res = await getQuestionnaires(this.curRevId);


      if(!res.data.isError){
        
        let questionnaires = !this.isSortByName ? res.data.questionnaires : res.data.questionnaires.sort((a,b) => (a.data.name > b.data.name) ? 1 : ((b.data.name > a.data.name) ? -1 : 0));
        
        // set to go back to the current subtopic selection
        if( this.questionnaires.length > 0 && questionnaires.length != this.questionnaires.length){
          let i = questionnaires.findIndex(q => q.subtopic === this.questionnaires[this.currentSelection.subtopic].subtopic);
          this.currentSelection.subtopic = i < 0 ? 0 : i;
          this.currentSelection.question = 0;
        }

        // this.questionnaires = !this.isSortByName ? questionnaires : questionnaires.sort((a,b) => (a.data.name > b.data.name) ? 1 : ((b.data.name > a.data.name) ? -1 : 0));
        this.questionnaires = questionnaires;
        this.setTagsPerQuestion()
      }
      this.isGettingQuestionnaires = false;
      this.getSubtopics();
    },
    setTagsPerQuestion(){
      getTagsQuestions(this.curRevId)
      .then((res) => {
        // this.tagsQuestions = res.tagsQuestions;
        this.questionnaires.forEach(qnr => {
          qnr.data.questions.forEach(q=>{
            q.tags = [];
            res.tagsQuestions.filter(tq => tq.data.questionId == q.id && tq.data.questionnaireId == qnr.subtopic).forEach(tq2=>{
              q.tags.push( this.tags.find(t => t.id == tq2.data.tagId) )
            });
          })
        })

      })
    },
    async addQuestionnaire(){
      this.isProcessing = true;
      this.error.for = '';
      this.error.message = '';
      let res = await addQuestionnaire(this.curRevId,this.newSubtopic);
      if(!res.data.isError){
        this.getQuestionnaires();
        this.addNewSubtopicShow = false;
        swal.fire(
            'Success!',
            `${this.newSubtopic.name} has been added.`,
            'success'
        );
        this.newSubtopic = {};
      }else{
        this.error.for = 'add-subtopic';
        if(res.data.error == 'invalid-data'){
          this.error.message = 'Invalid data entered.';
        }else if(res.data.error == 'doc-already-exist'){
          this.error.message = 'Subtopic already exist.';
        }else{
          this.error.message = 'Unable to add new subtopic. Try again later.';
        }
      }
      this.isProcessing = false;
    },
    showAddSubtopic(){
      this.addNewSubtopicShow = !this.addNewSubtopicShow;
      this.newSubtopic = {};
      this.error.for = '';
      this.error.message = '';
    },
    selectSubtopic(i){
      // this.questionFormAreaHeight = this.$refs.questiondiv.clientHeight;
      this.currentSelection.subtopic = i;
      this.selectQuestion(0)
    },
    selectQuestion(i){
      // this.questionFormAreaHeight = this.$refs.questiondiv.clientHeight;
      this.currentSelection.question = i;
      if( this.questionnaires[this.currentSelection.subtopic].data.questions[i] && this.questionnaires[this.currentSelection.subtopic].data.questions[i].hasImage){
        this.isLoadingImage = true;
  
        document.getElementById('question-image-view-1').addEventListener("load", () => { this.isLoadingImage = false;});
      }
    },
    cancelAddQuestion(){
      this.closeModal();
    },
    async addNewQuestion(){

      this.isProcessing = true;
      this.error.for = '';
      this.error.message = '';
      // let choices = [];
      
      // get all choices and the correct answer
      // for(let i = 1; i <= this.addChoicesCount; i++){
      //   choices.push({
      //     choice: this.$refs[`choice_${i}`].value,
      //     id: i - 1
      //   });
      //   this.questionForm.choices = choices;
      //   if(this.$refs[`correct_answer_${i}`].checked){
      //     this.questionForm.answer = i - 1;
      //   }
      // }

      // get the new id
      let newId = 1;
      const questions = this.questionnaires[this.currentSelection.subtopic].data.questions;
      if(questions.length > 0){
        newId = (parseInt(questions.reduce((a,b)=> parseInt(a.id)>parseInt(b.id)?a:b).id)) + 1
      }
      this.questionForm.id = newId;

      // submit the new question form
      const subtopic = this.questionnaires[this.currentSelection.subtopic].subtopic;
      let res = await addQuestion(this.curRevId, this.selectedTags,{subtopic: subtopic, form: this.questionForm}, this.imageFile);

      // check response has error
      if(!res.data.isError){
        // if none show success alert and close modal
        this.getQuestionnaires();
        swal.fire(
            'Success!',
            `Question ${newId} has been added.`,
            'success'
        );
        this.imageFile = null;
        this.closeModal();
      }else{
        // it it has then show the errors
        this.error.for = 'add-question';
        this.error.message = res.data.error;
      }

      this.isProcessing = false;

    },
    openUpdateForm(){
      this.questionForm.id = this.questionnaires[this.currentSelection.subtopic].data.questions[this.currentSelection.question].id;
      this.questionForm.study = this.questionnaires[this.currentSelection.subtopic].data.questions[this.currentSelection.question].study;
      this.questionForm.question = this.questionnaires[this.currentSelection.subtopic].data.questions[this.currentSelection.question].question;
      this.questionForm.questionHtml = this.questionnaires[this.currentSelection.subtopic].data.questions[this.currentSelection.question].questionHtml;
      this.questionForm.choices = cloneDeep(this.questionnaires[this.currentSelection.subtopic].data.questions[this.currentSelection.question].choices);
      this.questionForm.answer = this.questionnaires[this.currentSelection.subtopic].data.questions[this.currentSelection.question].answer;
      this.questionForm.hasImage = this.questionnaires[this.currentSelection.subtopic].data.questions[this.currentSelection.question].hasImage;
      this.questionForm.imageUrl = this.questionnaires[this.currentSelection.subtopic].data.questions[this.currentSelection.question].imageUrl;
      this.questionForm.storageRefPath = this.questionnaires[this.currentSelection.subtopic].data.questions[this.currentSelection.question].storageRefPath;
      this.addChoicesCount = this.questionForm.choices.length;

      // populate tags
      this.questionnaires[this.currentSelection.subtopic].data.questions[this.currentSelection.question].tags.forEach(t=>{
        this.selectedTags.push(t.id);
      })

      this.isAdd=false;
      this.openModal();
    },
    async update(){
      
      this.isProcessing = true;
      this.error.for = '';
      this.error.message = '';
      // let choices = [];
      
      // get all choices and the correct answer
      // for(let i = 1; i <= this.addChoicesCount; i++){
      //   choices.push({
      //     choice: this.$refs[`choice_${i}`].value,
      //     id: i - 1
      //   });
      //   this.questionForm.choices = choices;
      //   if(this.$refs[`correct_answer_${i}`].checked){
      //     this.questionForm.answer = i - 1;
      //   }
      // }

      // submit the new question form
      const subtopic = this.questionnaires[this.currentSelection.subtopic].subtopic
      let res = await updateQuestion(this.curRevId, this.selectedTags, {subtopic: subtopic, form: this.questionForm}, this.imageFile);

      // check response has error
      if(!res.data.isError){
        // if none show success alert and close modal
        await this.getQuestionnaires();
        swal.fire(
            'Success!',
            `Question ${this.questionForm.id} has been updated.`,
            'success'
        );
        this.imageFile = null;
        this.closeModal();
      }else{
        // it it has then show the errors
        this.error.for = 'add-question';
        this.error.message = res.data.error;
      }

      this.isProcessing = false;

    },
    async deleteSubtopic(){

      const subtopic = this.questionnaires[this.currentSelection.subtopic].subtopic
      const isConfirmed = await swal.fire({
        title: 'Delete question?',
        text: `Are you sure you want to delete ${subtopic}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#db912a',
        cancelButtonColor: '#4a4848',
        confirmButtonText: 'Yes, delete'
      }).then(result => result.isConfirmed);
      if(!isConfirmed){return false;}


      this.isProcessing = true;
      let res = await deleteSubtopicFromQuestionnaire(this.curRevId, {subtopic});

      // check response has error
      if(!res.data.isError){
        // if none show success alert and close modal
        this.currentSelection.subtopic = 0;
        this.getQuestionnaires();
        swal.fire(
            'Success!',
            `${subtopic} has been deleted.`,
            'success'
        );
      }else{
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Something went wrong! ${res.data.error}`,
        })
      }
      this.isProcessing = false;
    },
    async delQuestion(){
      
      const qid = this.questionnaires[this.currentSelection.subtopic].data.questions[this.currentSelection.question].id;
      const isConfirmed = await swal.fire({
        title: 'Delete question?',
        text: `Are you sure you want to delete question ${qid}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#db912a',
        cancelButtonColor: '#4a4848',
        confirmButtonText: 'Yes, delete'
      }).then(result => result.isConfirmed);
      if(!isConfirmed){return false;}

      this.isProcessing = true;
      const storageRefPath = this.questionnaires[this.currentSelection.subtopic].data.questions[this.currentSelection.question].storageRefPath;
      const subtopic = this.questionnaires[this.currentSelection.subtopic].subtopic;
      let res = await deleteQuestion(this.curRevId, {qid,subtopic},storageRefPath);
      // check response has error
      if(!res.data.isError){
        // if none show success alert and close modal
        this.currentSelection.question = 0;
        this.getQuestionnaires();
        swal.fire(
            'Success!',
            `Question ${qid} has been deleted.`,
            'success'
        );
      }else{
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Something went wrong! ${res.data.error}`,
        })
      }

      this.isProcessing = false;
    },
    addChoice(){
      this.questionForm.choices.push({choice:"",choiceHtml:"",id:this.questionForm.choices.length});
    },
    removeChoice(i){
      this.questionForm.choices.splice(this.questionForm.choices.findIndex(c => c.id == i),1);
      // reset choices ID 
      this.questionForm.choices.forEach((c,i) => c.id = i);
    },
    uploadImage(){

      let reader;
      let qImage;
      let input = document.createElement('input');
      input.type = 'file';
      input.accept = '.png, .jpeg, .jpg';

      input.onchange = e => {
        qImage =  e.target.files[0];
        this.imageFile = qImage;
        reader = new FileReader();
        if (qImage) {
          reader = new FileReader();
          reader.onload = ()=>{
            document.getElementById('question-image').src = reader.result;
          }
          reader.readAsDataURL(qImage)
          this.questionForm.hasImage = true;
          this.questionForm.imageUrl = '';
        }
      }
      input.click();
    },
    removeImage(){
      this.questionForm.hasImage = false;
      this.questionForm.imageUrl = '';
      this.imageFile = null
    },
    closeModal() {
        this.isModalOpen = false;
        this.isAdd=true;
        // reset the error
        this.error.for = '';
        this.error.message = null;
        // reset the form field
        this.questionForm.id = '';
        this.questionForm.study = '';
        this.questionForm.question = '';
        this.questionForm.questionHtml = '';
        this.questionForm.choices = [{choice:"",choiceHtml:"",id:0},{choice:"",choiceHtml:"",id:1}];
        this.questionForm.answer = 0;
        this.questionForm.hasImage = false;
        this.questionForm.imageUrl = '';
        this.questionForm.storageRefPath = '';
        this.addChoicesCount = 2;
        this.selectedTags = [];

        this.isStudyMode = false;
    },
    openModal() {
      this.imageFile = null;
      this.isModalOpen = true
    },
    
    async openExplanationModal(){

      // get question ID
      const questionId = this.questionnaires[this.currentSelection.subtopic].subtopic +'_'+ this.questionnaires[this.currentSelection.subtopic].data.questions[this.currentSelection.question].id;

      // reset explanation form
      this.explanationForm.docId = null;
      this.explanationForm.data.upvotes = 0;
      this.explanationForm.data.downvotes = 0;
      this.explanationForm.data.questionId = questionId;
      this.explanationForm.data.text = '';
      this.explanationForm.data.textHtml = '';

      const q = query(collection(db, "explanations"), where("questionId", "==", questionId));

    try {

      const querySnapshot = await getDocs(q);

      // Check if any documents were found
      if (querySnapshot.size > 0) {

      
          // this should loop only once, or return 1 doc
          querySnapshot.forEach((doc) => {

            const docData = doc.data();

            // convert raw data text to WYSIWYG compatible
            let toWYSIWYG = '';
            if (docData.text && !docData.textHtml) {
              let processedExplanation = null;
              processedExplanation = docData.text.replace(/(?:\r\n|\r|\n)/g, '\n\n');
              let prags = processedExplanation.split(/(?:\r\n|\r|\n)/g).filter(item => item !== '');
              prags.forEach(prag => {
                toWYSIWYG = toWYSIWYG + `<p>${prag}<p/>`
              })
            }

            this.explanationForm.docId = doc.id;
            this.explanationForm.data.upvotes = docData.upvotes ? docData.upvotes : 0;
            this.explanationForm.data.downvotes = docData.downvotes ? docData.downvotes : 0;
            this.explanationForm.data.questionId = docData.questionId;
            this.explanationForm.data.text = docData.text ? docData.text :  '';
            this.explanationForm.data.textHtml = docData.textHtml ? docData.textHtml.trim() : docData.text ? toWYSIWYG : '';
          });
      }

    } catch (error) {
      console.error('Error fetching document:', error.message);
    }

    this.isExplanationModalOpen = true;

    },
    closeExplanationModal(){
      this.isExplanationModalOpen = false;
    },
    async saveExplanation(){

      this.isProcessing = true;

      if (this.explanationForm.docId !== null) {

        // update explanation
        await updateDoc(doc(db, 'explanations', this.explanationForm.docId), this.explanationForm.data)
        .then(() => {
          console.log('Explanation updated successfully.');
        })
        .catch((error) => {
          console.error('Error updating explanation:', error.message);
        });

      }else{

        // add explanation
        await addDoc(collection(db, 'explanations'), this.explanationForm.data)
        .then((docRef) => {
          console.log('Explanation has been added');
        })
        .catch((error) => {
          console.error('Error adding explanation:', error.message);
        });

      }

      this.closeExplanationModal();
      this.isProcessing = false;


    },
    async delExplanation(){

      this.isProcessing = true;
      await deleteExplanation(this.explanationForm.docId);
      this.closeExplanationModal();
      this.isProcessing = false

    },
    resetVoteCounter(){
      this.explanationForm.data.upvotes = 0;
      this.explanationForm.data.downvotes = 0;
    },
    closeSearchModal(){
      this.isSearchModalOpen = false;
      this.toSearch = '';
      this.searchResult = [];
    },
    openSearchModal(){
      this.isSearchModalOpen = true;
    }
    
  },
}
</script>
<style scoped>
/* Toggle A */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}
</style>