<template>
    <div v-if="isAuth && announcementPopup.length > 0" class="fixed z-50 m-0 w-full h-full p-7 bg-warm-gray-600 bg-opacity-75">

      <div class="mx-auto w-full md:w-2/3 lg:w-2/3">
        <div class="flex flex-col p-5 rounded-lg shadow bg-white">
        <div class="flex flex-col items-center text-center">
          <div class="inline-block p-4 bg-yellow-50 rounded-full">
            <!-- alert icon -->
            <!-- <svg class="w-12 h-12 fill-current text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"/></svg> -->

            <!-- announcement icon -->
            <svg class="w-12 h-12 fill-current text-yellow-500" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z"/><path fill="currentColor" d="M19 4.741V8a3 3 0 1 1 0 6v3c0 1.648-1.881 2.589-3.2 1.6l-2.06-1.546A8.658 8.658 0 0 0 10 15.446v2.844a2.71 2.71 0 0 1-5.316.744l-1.57-5.496a4.7 4.7 0 0 1 3.326-7.73l3.018-.168a9.344 9.344 0 0 0 4.19-1.259l2.344-1.368C17.326 2.236 19 3.197 19 4.741ZM5.634 15.078l.973 3.407A.71.71 0 0 0 8 18.29v-3.01l-1.56-.087a4.723 4.723 0 0 1-.806-.115ZM17 4.741L14.655 6.11A11.343 11.343 0 0 1 10 7.604v5.819c1.787.246 3.488.943 4.94 2.031L17 17V4.741ZM8 7.724l-1.45.08a2.7 2.7 0 0 0-.17 5.377l.17.015l1.45.08V7.724ZM19 10v2a1 1 0 0 0 .117-1.993L19 10Z"/></g></svg>
          </div>
          <h2 class="mt-2 font-semibold text-gray-800">{{ announcementPopup[0].data.title }}</h2>
          <p class="mt-2 text-sm text-gray-600 leading-relaxed">{{announcementPopup[0].data.message}}</p>
        </div>

        <div class="flex items-center justify-center mt-3">
          <!-- <button class="flex-1 px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 text-sm font-medium rounded-md">
          Cancel
          </button> -->

          <button class=" px-10 py-2 ml-2 bg-yellow-500 hover:bg-yellow-600 text-white text-sm font-medium rounded-md" @click="closeAnnouncementPopup(announcementPopup[0].id)">
            OK
          </button>
        </div>
        </div>
      </div>

    </div>



    <router-view v-if="!isAuth" />
    <!-- Show the new MTP dashboard -->    
    <router-view v-else-if="$route.path.includes('/v2')" />
    <router-view v-else-if="$route.path.includes('/nest')" />
    <router-view v-else-if="$route.path.includes('/dome')" />
    <router-view v-else-if="$route.path.includes('/monitor')" />
    <div v-else class="relative h-screen flex overflow-hidden text-sm">
      <TransitionRoot as="template" :show="mobileMenuOpen">
        <Dialog
          as="div"
          class="fixed inset-0 flex z-40 lg:hidden"
          @close="mobileMenuOpen = false"
        >
          <TransitionChild
            as="template"
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-warm-gray-600 bg-opacity-75" />
          </TransitionChild>
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <div
              class="
                relative
                flex-1 flex flex-col
                max-w-xs
                w-full
                bg-white
                focus:outline-none
              "
            >
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute top-0 right-0 -mr-12 pt-4">
                  <button
                    type="button"
                    class="
                      ml-1
                      flex
                      items-center
                      justify-center
                      h-10
                      w-10
                      rounded-full
                      focus:outline-none
                      focus:ring-2 focus:ring-inset focus:ring-white
                    "
                    @click="mobileMenuOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="pt-5 pb-4">
                <div class="flex-shrink-0 flex items-center px-4 text-warm-gray-600">
                  <Logo class="h-8 w-auto" />
                  <h4 class="ml-4 font-bold tracking-wide text-xl">SKILLTECH PH</h4>
                </div>
                <nav aria-label="Sidebar" class="mt-5">
                  <div class="px-2 space-y-1">
                    <!-- <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[(item.name == $route.name) ? 'bg-warm-gray-50 text-warm-gray-900' : 'hover:bg-warm-gray-50 hover:text-warm-gray-900', 'group p-2 rounded-md flex items-center text-base font-medium text-warm-gray-600']">
                      <component :is="item.icon" class="mr-4 h-6 w-6 text-warm-gray-400 group-hover:text-warm-gray-500" aria-hidden="true" />
                      {{ item.name }}
                    </a> -->
                    <router-link
                      v-for="item in navigation"
                      :key="item.name"
                      :to="item.href"
                      @click="mobileMenuOpen = false"
                      :class="[
                        item.name == $route.name
                          ? 'bg-warm-gray-50 text-warm-gray-900'
                          : 'hover:bg-warm-gray-50 hover:text-warm-gray-900',
                        'group p-2 rounded-md flex items-center text-base font-medium text-warm-gray-600',
                      ]"
                    >
                      <component
                        :is="item.icon"
                        class="mr-4 h-6 w-6 text-warm-gray-400 group-hover:text-warm-gray-500"
                        aria-hidden="true"
                      />
                      {{ item.name }}
                    </router-link>

                     <button @click="toggleDarkMode()" class="hover:bg-warm-gray-50 hover:text-warm-gray-900 group p-2 rounded-md flex items-center text-base font-medium text-warm-gray-600">
                      <span v-if="!isDarkMode" class="flex justify-start items-center"> 
                        <MoonIcon  class="mr-4 h-6 w-6 text-warm-gray-400 group-hover:text-warm-gray-500" />Dark Mode
                      </span>
                      <span v-else  class="flex justify-start items-center">
                        <SunIcon class="mr-4 h-6 w-6 text-warm-gray-400 group-hover:text-warm-gray-500" />Light Mode
                      </span>
                    </button>
                    
                    <router-link
                      to="/logout"
                      class="hover:bg-warm-gray-50 hover:text-warm-gray-900 group p-2 rounded-md flex items-center text-base font-medium text-warm-gray-600"
                      >
                        <LogoutIcon class="mr-4 h-6 w-6 text-warm-gray-400 group-hover:text-warm-gray-500" />
                        <span>Logout</span>
                    </router-link>
           

                  </div>
                </nav>
              </div>

              <div class="flex-shrink-0 flex border-t border-warm-gray-200 p-4">
                  <div class="flex items-center">
                   <small class=" text-warm-gray-600">{{ adminVersion }}</small>
                  </div>
              </div>

              <!-- Account / Avatar -->
              <!-- <div class="flex-shrink-0 flex border-t border-warm-gray-200 p-4">
                <a href="#" class="flex-shrink-0 group block">
                  <div class="flex items-center">
                    <div>
                      <img
                        class="inline-block h-10 w-10 rounded-full"
                        src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.5&w=256&h=256&q=80"
                        alt=""
                      />
                    </div>
                    <div class="ml-3">
                      <p
                        class="
                          text-base
                          font-medium
                          text-warm-gray-700
                          group-hover:text-warm-gray-900
                        "
                      >
                        Lisa Marie
                      </p>
                      <p
                        class="
                          text-sm
                          font-medium
                          text-warm-gray-500
                          group-hover:text-warm-gray-700
                        "
                      >
                        Account Settings
                      </p>
                    </div>
                  </div>
                </a>
              </div> -->

            </div>
          </TransitionChild>
          <div class="flex-shrink-0 w-14" aria-hidden="true">
            <!-- Force sidebar to shrink to fit close icon -->
          </div>
        </Dialog>
      </TransitionRoot>

      <!-- Static sidebar for desktop -->
      <div class="hidden lg:flex lg:flex-shrink-0">
        <div class="flex flex-col w-20">
          <div
            class="flex-1 flex flex-col min-h-0 overflow-y-auto bg-warm-gray-600"
            >

            <div class="flex-1 flex flex-col">
              <div
                  class="
                    flex-shrink-0
                    bg-warm-gray-700
                    py-4
                    flex
                    items-center
                    justify-center
                  "
                >
                <Logo class="h-8 w-auto text-yellow-400" />
              </div>
              
              <nav
                  aria-label="Sidebar"
                  class="py-6 flex flex-col items-center space-y-3"
                >
                <!-- <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[(item.name == $route.name) ? 'bg-warm-gray-700' : 'hover:bg-warm-gray-700', 'flex items-center p-4 rounded-lg text-warm-gray-200']">
                  <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                  <span class="sr-only">{{ item.name }}</span>
                </a> -->
                <router-link
                  v-for="item in navigation"
                  :key="item.name"
                  :to="item.href"
                  :class="[
                    item.name == $route.name
                      ? 'bg-warm-gray-700'
                      : 'hover:bg-warm-gray-700',
                    'flex items-center p-4 rounded-lg text-warm-gray-200',
                  ]"
                >
                  <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                  <span class="sr-only">{{ item.name }}</span>
                </router-link>

                <button @click="toggleDarkMode()" class="flex items-center p-4 rounded-lg text-warm-gray-200 hover:bg-warm-gray-700">
                  <MoonIcon v-if="!isDarkMode" class="h-6 w-6" />
                  <SunIcon v-else class="h-6 w-6" />
                </button>
                <router-link
                  to="/logout"
                  class="flex items-center p-4 rounded-lg text-warm-gray-200 hover:bg-warm-gray-700"
                  >
                    <LogoutIcon class="h-6 w-6" />
                    <span class="sr-only">Logout</span>
                </router-link>
              </nav>
            </div>

            <div class="flex-shrink-0 flex p-2">
                <div class="w-full text-center">
                  <small class="text-warm-gray-100">{{ adminVersion }}</small>
                </div>
            </div>

            <!-- <div class="flex-shrink-0 flex">
              <a href="#" class="flex-shrink-0 w-full">
                <img
                  class="block mx-auto h-10 w-10 rounded-full"
                  src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.5&w=256&h=256&q=80"
                  alt=""
                />
                <div class="sr-only">
                  <p>Lisa Marie</p>
                  <p>Account settings</p>
                </div>
              </a>
            </div> -->

          </div>
        </div>
      </div>

      <div class="flex-1 min-w-0 flex flex-col overflow-hidden">

        <!-- Mobile top navigation -->
        <div class="lg:hidden">
          <div
            class="
              bg-warm-gray-600
              py-2
              px-4
              flex
              items-center
              justify-between
              sm:px-6
            "
          >
            <div>
              <Logo class="h-8 w-auto text-yellow-400" />
            </div>
            <div>
              <button
                type="button"
                class="
                  -mr-3
                  h-12
                  w-12
                  inline-flex
                  items-center
                  justify-center
                  bg-warm-gray-600
                  rounded-md
                  text-white
                  hover:bg-warm-gray-700
                  focus:outline-none
                  focus:ring-2 focus:ring-inset focus:ring-white
                "
                @click="mobileMenuOpen = true"
              >
                <span class="sr-only">Open sidebar</span>
                <MenuIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        <main class="flex-1 flex overflow-hidden">
          <div class="flex-1 flex flex-col overflow-y-auto xl:overflow-hidden">
            <div class="flex-1 flex xl:overflow-hidden">
              <!-- Main content -->
              <div class="flex-1 xl:overflow-y-auto dark:text-warm-gray-200 dark:bg-warm-gray-900">
                <div class="max-w-full mx-auto py-6 px-4 sm:px-6 lg:py-6 lg:px-6 dark:bg-warm-gray-900">
                  <router-view></router-view>
                </div>
              </div>
            </div>
          </div>
        </main>

      </div>
    </div>

    
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

// import {
//   HomeIcon,
//   MenuIcon,
//   LogoutIcon,
//   XIcon,
//   UsersIcon,
//   CollectionIcon,
//   AdjustmentsIcon,
//   MoonIcon,
//   SunIcon,
//   BellIcon
// } from "@heroicons/vue/outline";


/**
 * UI dependencies
 */
 import {
  HomeIcon as HomeIcon,
  Bars3Icon as MenuIcon,
  ArrowLeftOnRectangleIcon as LogoutIcon,
  XMarkIcon as XIcon,
  UsersIcon,
  RectangleStackIcon as CollectionIcon,
  AdjustmentsVerticalIcon as AdjustmentsIcon,
  CommandLineIcon as CommandLineIcon,
  MoonIcon as MoonIcon,
  SunIcon as SunIcon,
  BellIcon as BellIcon,
  ClockIcon as Clock,
  CurrencyDollarIcon,
  NoSymbolIcon,
  ChartBarSquareIcon,
  GiftIcon,
  // BookOpenIcon as BookOpenIcon,
  BookmarkSquareIcon as BookmarkSquareIcon
} from "@heroicons/vue/24/outline";


const navigation = [
  { name: "Dashboard", href: "/", icon: HomeIcon },
  { name: 'Reviewers', href: "/reviewers", icon: BookmarkSquareIcon},
  { name: "Data", href: "/data/questionnaires", icon: CollectionIcon },
  { name: "Authentication", href: "/authentication", icon: UsersIcon },
  { name: "Dash", href: "/dash", icon: AdjustmentsIcon },
  { name: "V2", href: "/v2", icon: CommandLineIcon },
  { name: "Soft blocks", href: "/soft-blocks", icon: NoSymbolIcon},
  { name: "Scorecard", href: "/scorecard", icon: ChartBarSquareIcon},
  { name: "Reward", href: "/reward", icon: GiftIcon},
  { name: "Finance", href: "/finance", icon: CurrencyDollarIcon },
  // { name: "Scheduler", href: "/scheduler/announcements", icon: Clock },
  // {name: "Notification", href: "/fcm", icon: BellIcon}

  // { name: "Logout", href: "/logout", icon: LogoutIcon },
];
import store from '@/store'
import {announcementSeenByUser} from "@/firebase/utils/scheduler";
import {mapGetters} from 'vuex';
import Logo from "@/components/Logo";

export default {
  data() {
    return {
      adminVersion: 'v0.3.21',
      isDarkMode: false,
      mobileMenuOpen: false,
      navigation,
    }
  },  
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    LogoutIcon,
    MenuIcon,
    XIcon,
    MoonIcon,
    SunIcon,
    BellIcon,
    Logo,
    Clock,
  },
  created() {

    // console.log(!("theme" in localStorage), window.matchMedia("(prefers-color-scheme: dark)").matches);

    if (localStorage.theme === "dark" ){
      this.isDarkMode = true;
      document.documentElement.classList.add("dark");
    } else {
      this.isDarkMode = false;
      document.documentElement.classList.remove("dark");
    }

    // if (localStorage.theme === "dark" || (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches) ){
    //   document.documentElement.classList.add("dark");
    //   this.isDarkMode = true;
    // } else {
    //   document.documentElement.classList.remove("dark");
    //   this.isDarkMode = false;
    // }

  },
  mounted() {
    // console.log(store.state.user.credentials.email);
    // console.log(store.state.user.credentials.uid);
    // console.log('mounted app', this.announcementPopup[0].id)
  },
  computed:{
    ...mapGetters(['isAuth','user', 'announcementPopup'])
  },
  watch:{
    announcementPopup: {
        handler: function () {
            if (this.announcementPopup.length > 0) {
              this.popupInfo = this.announcementPopup[0]
            }
        },
        deep: true
    },
  },
  methods: {
    toggleDarkMode(){
      this.isDarkMode = !this.isDarkMode;
      if(this.isDarkMode){
        window.localStorage.setItem('theme', 'dark');
        document.documentElement.classList.add("dark");
      }else{
        window.localStorage.removeItem('theme');
        document.documentElement.classList.remove("dark");
      }

    },
    closeAnnouncementPopup(announcementId){
      // remove from vuex store
      store.dispatch('removeAnnouncementPopup', announcementId);
      // add user uid to seen_by array into this announcement
      announcementSeenByUser(this.user.credentials.uid, announcementId)
    }
  },
}
</script>
